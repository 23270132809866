import React from "react";
import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import colors from "@assets/styles/colors";

interface IPasswordResetProps {}

const Form = styled.div`
  width: 400px;
  min-height: 520px;
  margin: 30px auto;
  background: ${colors.white};
  padding: 40px 83px;
`;

const Header = styled.div`
  padding: 30px 0px;
`;

const Row = styled.div`
  display: flex;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
`;

const HeaderImg = styled.img`
  height: 28px;
  padding: 6px 0px;
`;

const LoginContainer = styled.div`
  margin-top: 35px;
  margin-bottom: 35px;
`;

const List = styled.div``;

const SelectInput = styled.select``;

const PasswordReset: React.FC<IPasswordResetProps> = () => {
  const validateToken = () => {
    // this.$f7.input.validateInputs("#valid-token");
    // if(this.$$("#valid-token").find(".input-invalid").length > 0) {
    //     this.$f7.dialog.alert("Please enter a valid token to continue", "Invalid Token", () => { this.$f7.dialog.close(); });
    //     return;
    // }
    // this.$stitch.validateResetToken(token).then((data) => {
    //     console.log("Token Validated: ",data);
    //     this.token = data;
    //     this.validToken = true;
    // })
    // .catch((msg) => {
    //     this.$f7.dialog.alert("You have entered an invalid token, please try again.", "Invalid Token", () => { this.$f7.dialog.close(); });
    // });
  };
  const resetPassword = () => {
    // this.$f7.input.validateInputs("#pass-reset");
    // if(this.$$("#pass-reset").find(".input-invalid").length > 0) {
    //     this.$f7.dialog.alert("Please correct the errors and try again", "Password Reset Errors", () => { this.$f7.dialog.close(); });
    //     return;
    // }
    // this.$stitch.resetPassword(email).then((data) => {
    //     console.log("Password Reset Complete: ",data);
    //     this.$f7.dialog.alert("Your password has been successfully reset.","Password Reset Successful",() => { this.$f7.loginScreen.open("#my-login-screen") });
    // })
    // .catch((msg) => {
    //     this.$f7.dialog.alert("Error resetting password, please try again.", "Password Reset Error", () => { this.$f7.dialog.close(); });
    // });
  };
  const sendNewToken = () => {
    // this.$f7.input.validateInputs("#pass-reset");
    // if(this.$$("#pass-reset").find(".input-invalid").length > 0) {
    //     this.$f7.dialog.alert("Please correct the errors and try again", "Password Reset Errors", () => { this.$f7.dialog.close(); });
    //     return;
    // }
    // this.$stitch.resetPassword(email).then((data) => {
    //     console.log("Password Reset Complete: ",data);
    //     this.$f7.dialog.alert("Your password has been successfully reset.","Password Reset Successful",() => { this.$f7.loginScreen.open("#my-login-screen") });
    // })
    // .catch((msg) => {
    //     this.$f7.dialog.alert("Error resetting password, please try again.", "Password Reset Error", () => { this.$f7.dialog.close(); });
    // });
  };
  return (
    <Column>
      <Form>
        <Header>
          <Column className="text-align-left">
            <Title>Recovery Token</Title>
          </Column>
          <Column className="text-align-right">
            <HeaderImg src="../images/stitch_horizontal.png" />
          </Column>
        </Header>
        <Row>
          <Column>
            <span style={{ fontWeight: 400, fontSize: 18 }}>
              Enter your password recovery token to continue
            </span>
          </Column>
          <Column></Column>
        </Row>
        <Row>
          <Column>
            <List>
              <label htmlFor="">Password Recovery Token</label>
              <input
                type="text"
                placeholder="Password Recovery Token"
                error-message="Password Recovery Token required"
                floating-label
                required
                // validate
              />
            </List>
          </Column>
        </Row>
        <Row>
          <Column>
            <Button onClick={validateToken}>Validate Token</Button>
          </Column>
        </Row>
        <Row>
          <Column>
            <Link onClick={sendNewToken}>click here</Link> to generate a new
            password recovery token.
          </Column>
        </Row>
        <Row>
          <LoginContainer>
            Return to{" "}
            <Link component={RouterLink} to="/login">
              Log In!
            </Link>
          </LoginContainer>
        </Row>
        <Row>
          <Column>
            <Link component={RouterLink} to="/login">
              Login
            </Link>
          </Column>
        </Row>
      </Form>
      <Form>
        <Header>
          <Column className="text-align-left">
            <Title>Password Reset</Title>
          </Column>
          <Column className="text-align-right">
            <HeaderImg src="../images/stitch_horizontal.png" />
          </Column>
        </Header>
        <Row>
          <Column>
            <span style={{ fontWeight: 400, fontSize: 18 }}>
              Answer your recovery question and enter your new password to
              continue.
            </span>
          </Column>
          <Column></Column>
        </Row>
        <Row>
          <Column>
            <List>
              <SelectInput>
                <option value=""></option>
                <option value="1">What Is your favorite book?</option>
                <option value="2">
                  What is the name of the road you grew up on?
                </option>
                <option value="3">What is your mother’s maiden name?</option>
                <option value="4">
                  What was the name of your first/current/favorite pet?
                </option>
                <option value="5">
                  What was the first company that you worked for?
                </option>
                <option value="6">Where did you meet your spouse?</option>
                <option value="7">
                  Where did you go to high school/college?
                </option>
                <option value="8">What is your favorite food?</option>
                <option value="9">What city were you born in?</option>
                <option value="10">
                  Where is your favorite place to vacation?
                </option>
              </SelectInput>
              <input
                type="text"
                placeholder="Password Recovery Answer"
                error-message="An answer to your password recovery question is required"
                floating-label
                required
                // validate
              />
              <input
                type="password"
                placeholder="Password"
                error-message="Password requirements: 1 upper case letter, 1 lower case letter, 1 number, 8 characters min"
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                required
                // validate
              />
              <input
                type="password"
                placeholder="Re-enter Password"
                error-message="Passwords do not match"
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                required
                // validate
              />
            </List>
          </Column>
        </Row>
        <Row>
          <Column>
            <Button onClick={resetPassword}>Reset Password</Button>
          </Column>
        </Row>
      </Form>
    </Column>
  );
};

export default PasswordReset;

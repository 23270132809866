import React from "react";
import styled from "styled-components";
import colors from "@assets/styles/colors";

import Figure1 from "@assets/images/figure_1.svg";
import Figure2 from "@assets/images/figure_2.svg";
import Figure3 from "@assets/images/figure_3.svg";
import Figure4 from "@assets/images/figure_4.svg";

interface IProps {
  children: React.ReactChild;
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  background: ${colors.background};
`;

const Fig1 = styled.img`
  top: 15%;
  left: 25%;
  position: absolute;
`;

const Fig2 = styled.img`
  top: 15%;
  right: 15%;
  position: absolute;
`;

const Fig3 = styled.img`
  left: 25%;
  bottom: 15%;
  position: absolute;
`;

const Fig4 = styled.img`
  right: 25%;
  bottom: 15%
  position: absolute;
`;

const Wrapper = styled.div`
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;

  > div {
    width: 100%;
    position: relative;
    background-color: white;
    border: 1px solid black;
  }
`;

const ContentWrapper: React.FC<IProps> = ({ children }) => {
  return (
    <Container>
      <Fig1 src={Figure1} />
      <Fig2 src={Figure2} />
      <Fig3 src={Figure3} />
      <Fig4 src={Figure4} />
      <Wrapper>
        <div>{children}</div>
      </Wrapper>
    </Container>
  );
};

export default ContentWrapper;

import axios from "axios";
import { setupCache } from "axios-cache-adapter";
// import { Route } from "@utils/enums/routes";
import { isTokenValid, removeAccessToken, getAccessToken } from "@utils/token";

const CACHE_EXPIRE = 900000;
const cache = setupCache({ maxAge: CACHE_EXPIRE });

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

instance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // window.location.pathname = `/${Route.LOGIN}`;
    }
    return Promise.reject();
  }
);

export const sAxios = axios.create({ baseURL: process.env.REACT_APP_API_URL });
sAxios.interceptors.request.use(
  config => {
    if (isTokenValid()) {
      config.headers.Authorization = "Bearer " + getAccessToken();
      return config;
    }
    return Promise.reject(new Error("Auth Required"));
  },
  error => {
    return Promise.reject(error);
  }
);
sAxios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (!error.response || error.response.status === 401) {
      removeAccessToken();
      // logout();
      // Redirect here
      delete sAxios.defaults.headers.common["Authorization"];
    }
    return Promise.reject(error);
  }
);

export const eAxios = axios.create({ adapter: cache.adapter });

export default instance;

//TODO: Reuse some api calls
//export default {
//     initialize() {
//         return instance({url: STITCH_BASE_URL+"/users/initialize", method: "get"})
//         .then((response) => { return response.data; })
//     },
//     userLogin(username,password) {
//         return instance({url: STITCH_BASE_URL+"/users/login", method: "post", data: {username: username, password: password}})
//         .then((response) => { store.commit("ACCESS_TOKEN", response.data.token); return response.data; })
//     },
//     registerUser(data) {
//         return instance({url: STITCH_BASE_URL+"/users/register", method: "post", data: data})
//         .then((response) => { store.commit("ACCESS_TOKEN", response.data.token); return response.data; })
//     },
//     identityUser(data) {
//         return sAxios({url: "/users/identity", method: "post", data: data})
//         .then((response) => { return response.data; })
//     },
//     getUser() {
//         return sAxios({url: "/users", method: "get"})
//         .then((response) => { store.commit("USER", response.data); return response.data; })
//     },
//     getQuiz() {
//         return sAxios({url: "/users/get-quiz", method: "get"})
//         .then((response) => { return response.data; })
//     },
//     verifyQuiz(data) {
//         return sAxios({url: "/users/verify-quiz", method: "post", data: data})
//         .then((response) => { return response.data; })
//     },
//     verifyPhone() {
//         console.log("Verifying Phone...");
//         return sAxios({url: "/users/get-mobile", method: "get"})
//         .then((response) => { return response.data; });
//     },
//     sendCode() {
//         return sAxios({url: "/users/send-code", method: "post"})
//         .then((response) => { return response.data; })
//     },
//     sendNewCode(data) {
//         return sAxios({url: "/users/renew-code", method: "post", data: data})
//         .then((response) => { return response.data; })
//     },
//     verifyCode(data) {
//         return sAxios({url: "/users/verify-code", method: "post", data: data})
//         .then((response) => { return response.data; })
//     },
//     verifyEnrollment() {
//         return sAxios({url: "/users/enrollment-check", method: "post"})
//         .then((response) => { return response.data; })
//     },
//     recoverPassword(data) {
//
//     },
//     validateResetToken(data) {
//
//     },
//     resetPassword(data) {
//
//     },
//     closeAccount(data) {
//         return Promise.resolve(1);
//     },
//     getDeliveryToken(count=1) {
//         //console.log("getDeliveryToken("+count+")");
//
//         if(eAxios.defaults.headers.Authorization && eAxios.defaults.baseURL)
//             return Promise.resolve();
//
//         return sAxios({url: "/users/efx-config", method: "get"})
//             .then((resp) => {
//                 //console.log("EFX Config: ",resp.data);
//                 eAxios.defaults.baseURL = resp.data.url;
//                 return instance{url: resp.data.url + "/oauth/token", method: "post", params: {scope: "delivery", grant_type: "jwt-bearer", api_key: resp.data.id, client_assertion: resp.data.secret}})
//                     .then((response) => { eAxios.defaults.headers.Authorization = "Bearer " + response.data.access_token; })
//                     .catch((error) => { if(!error.response || error.response.status == 401) { this.logout(); throw error; } });
//         })
//     },
//     getScoreLatest(count=1) {
//         if(!(eAxios.defaults.headers.Authorization && eAxios.defaults.baseURL))
//             return this.getDeliveryToken().then(() => { return this.getScoreLatest() });
//         return eAxios({url: "/v1/creditScore/latest", method: "get"})
//         .then((response) => { return response.data; })
//         .catch((error) => { return this.handleEFXError(error, count++, this.getScoreLatest) });
//     },
//     getScoreHistory(count=1) {
//         if(!(eAxios.defaults.headers.Authorization && eAxios.defaults.baseURL))
//             return this.getDeliveryToken().then(() => { return this.getScoreHistory() });
//         return eAxios({url: "/v1/creditScore/history", method: "get"})
//         .then((response) => { return response.data; })
//         .catch((error) => { return this.handleEFXError(error, count++, this.getScoreHistory) });
//     },
//     getReportLatest(count=1) {
//         if(!(eAxios.defaults.headers.Authorization && eAxios.defaults.baseURL))
//             return this.getDeliveryToken().then(() => { return this.getReportLatest() });
//         return eAxios({url: "/v1/creditReport/latest", method: "get"})
//         .then((response) => { return response.data; })
//         .catch((error) => { return this.handleEFXError(error, count++, this.getReportLatest) });
//     },
//     getReportList(count=1) {
//         if(!(eAxios.defaults.headers.Authorization && eAxios.defaults.baseURL))
//             return this.getDeliveryToken().then(() => { return this.getReportList() });
//         return eAxios({url: "/v1/creditReport", method: "get"})
//         .then((response) => { return response.data; })
//         .catch((error) => { return this.handleEFXError(error, count++, this.getReportList) });
//     },
//     getReportSummary(reportId, count=1) {
//         if(!(eAxios.defaults.headers.Authorization && eAxios.defaults.baseURL))
//             return this.getDeliveryToken().then(() => { return this.getReportSummary(reportId) });
//         return eAxios({url: `/v1/creditReport/${reportId}/summary`, method: "get"})
//         .then((response) => { return response.data; })
//         .catch((error) => { return this.handleEFXError(error, count++, this.getReportSummary, reportId) });
//     },
//     getMonitoringAlerts(count=1) {
//         if(!(eAxios.defaults.headers.Authorization && eAxios.defaults.baseURL))
//             return this.getDeliveryToken().then(() => { return this.getMonitoringAlerts() });
//         return eAxios({url: "/v1/creditMonitoring", method: "get"})
//         .then((response) => { return response.data; })
//         .catch((error) => { return this.handleEFXError(error, count++, this.getMonitoringAlerts) });
//     },
//     handleEFXError(error, count, fn, data) {
//         if(!error.response || error.response.status == 401) {
//             delete eAxios.defaults.headers.common["Authorization"];
//             eAxios.defaults.baseURL = null;
//             if(count++ > RETRY_COUNT)
//                 return this.logout();
//             return this.getDeliveryToken()
//                 .then(() => { return data ? fn(count, data) : fn(count) })
//         }
//     }
// }

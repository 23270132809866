import React, { useState } from "react";
import styled from "styled-components";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Button, TextField } from "@material-ui/core";
import colors from "@assets/styles/colors";
import fontSizes from "@assets/styles/fontSizes";
import LogoSrc from "@assets/images/logo.png";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
        width: "100%"
      }
    },
    submit: {
      marginTop: 100
    },
    description: {
      lineHeight: "175%",
      letterSpacing: "0.03em",
      fontSize: fontSizes.paragraph1
    }
  })
);

interface IProps {
  nextStep: Function;
}

const Logo = styled.img`
  height: 25px;
  padding: 6px 0;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Resend = styled.a`
  display: block;
  text-align: right;
  color: ${colors.lightPink};
  font-size: ${fontSizes.paragraph2};
`;

const VerificationCode: React.FC<IProps> = ({ nextStep }) => {
  const classes = useStyles();
  const [verCode, setVerCode] = useState<string>("");
  const [isCodeResent, setIsCodeResent] = useState<boolean>(false);

  const resendCode = () => {
    // console.log("code resent");
    setIsCodeResent(true);
  };

  const handleSubmit = () => {
    // console.log("code", verCode);
    nextStep();
  };

  return (
    <React.Fragment>
      <Header>
        <h1>
          Forgot
          <br />
          Password
        </h1>
        <Logo src={LogoSrc} />
      </Header>
      <form className={classes.root} onSubmit={handleSubmit} color="primary">
        <p className={classes.description}>
          Please enter your verification code.
        </p>
        <TextField
          required
          type="text"
          id="verCode"
          value={verCode}
          color="secondary"
          label="Verification code"
          placeholder="Enter your verification code"
          onInput={e => {
            const target = e.target as HTMLInputElement;
            setVerCode(target.value);
          }}
        />

        {!isCodeResent && (
          <Resend
            href={"#"}
            onClick={() => {
              resendCode();
            }}
          >
            <span>Send a new code</span>
          </Resend>
        )}
        {isCodeResent && (
          <Resend>
            <span>New code sent!</span>
          </Resend>
        )}
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          className={classes.submit}
        >
          CONTINUE
        </Button>
      </form>
    </React.Fragment>
  );
};

export default VerificationCode;

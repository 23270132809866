import React from "react";
import styled from "styled-components";
import { Link as L } from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/core";
import ContentWrapper from "@components/ContentWrapper";
import fontSizes from "@assets/styles/fontSizes";
import colors from "@assets/styles/colors";

const useStyles = makeStyles(theme =>
  createStyles({
    wrapper: {
      padding: "60px 80px",
      [theme.breakpoints.down("md")]: {
        padding: "15px 25px 15px 15px"
      }
    }
  })
);

interface IProps {}

const Wrapper = styled.div`
  width: 300px;
  text-align: left;
  position: relative;
`;

const SettingsItems = styled.div`
  display: block;
  margin-top: 50px;
  min-height: 200px;
`;

const Link = styled(L)`
  width: 100%;
  display: block;
  margin-top: 15px;
  color: ${colors.lightPink};
  font-size: ${fontSizes.paragraph2};
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  padding-top: 5px;
  justify-items: space-between;
  border-top: 1px solid ${colors.gray};

  > :last-of-type {
    text-align: right;
  }
`;

const Settings: React.FC<IProps> = () => {
  const classes = useStyles();

  return (
    <ContentWrapper>
      <Wrapper className={classes.wrapper}>
        <h1>Settings</h1>
        <SettingsItems>
          <Link to={"/change-password"}>Change your password</Link>
          <Link to={"/change-recovery-question"}>
            Change your recovery question
          </Link>
          <Link to={"/change-email"}>Change your mail</Link>
          <Link to={"/close-account"}>Close account</Link>
        </SettingsItems>
        <Footer>
          <Link to={"/terms-of-use"}>Terms of Use</Link>
          <Link to={"/privacy-policy"}>Privacy Policy</Link>
        </Footer>
      </Wrapper>
    </ContentWrapper>
  );
};

export default Settings;

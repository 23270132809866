import React, { useState } from "react";
import styled from "styled-components";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Button, TextField } from "@material-ui/core";
import LogoSrc from "@assets/images/logo.png";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      maxWidth: "300px",
      "& > *": {
        margin: theme.spacing(1),
        width: "100%"
      }
    },
    submit: {
      marginTop: 100
    }
  })
);

interface IProps {
  nextStep: Function;
}

const Logo = styled.img`
  height: 25px;
  padding: 6px 0;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const NewPassword: React.FC<IProps> = ({ nextStep }) => {
  const classes = useStyles();
  const [newPassword, setNewPassword] = useState<string>("");
  const [repeatNewPassword, setRepeatNewPassword] = useState<string>("");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // console.log("secretAnswer", secretAnswer);
    if (newPassword === repeatNewPassword) nextStep();
  };

  return (
    <React.Fragment>
      <Header>
        <h1>
          Forgot
          <br />
          Password
        </h1>
        <Logo src={LogoSrc} />
      </Header>
      <form className={classes.root} onSubmit={handleSubmit} color="primary">
        <TextField
          required
          type="text"
          id="newPassword"
          color="secondary"
          value={newPassword}
          label="New password"
          placeholder="Enter your new password"
          onInput={e => {
            const target = e.target as HTMLInputElement;
            setNewPassword(target.value);
          }}
        />
        <TextField
          required
          type="text"
          color="secondary"
          id="repeatNewPassword"
          value={repeatNewPassword}
          label="Re-write new password"
          placeholder="Enter your new password"
          onInput={e => {
            const target = e.target as HTMLInputElement;
            setRepeatNewPassword(target.value);
          }}
        />
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          className={classes.submit}
        >
          CONTINUE
        </Button>
      </form>
    </React.Fragment>
  );
};

export default NewPassword;

import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { createStyles, makeStyles } from "@material-ui/core";
import ContentWrapper from "@components/ContentWrapper";
import fontSizes from "@assets/styles/fontSizes";
import UserNotFoundIcon from "@assets/images/user_not_found.svg";

const useStyles = makeStyles(theme =>
  createStyles({
    wrapper: {
      padding: "60px 80px",
      [theme.breakpoints.down("md")]: {
        padding: "15px 25px 15px 15px"
      }
    },
    button: {
      width: "100%",
      marginTop: 50
    },
    description: {
      marginTop: "15px",
      lineHeight: "175%",
      letterSpacing: "0.03em",
      fontSize: fontSizes.paragraph1
    }
  })
);
interface IProps {}

const Wrapper = styled.div`
  position: relative;
  text-align: center;
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-bottom: 15px;
`;

const UserNotFound: React.FC<IProps> = () => {
  const classes = useStyles();

  return (
    <ContentWrapper>
      <Wrapper className={classes.wrapper}>
        <Logo src={UserNotFoundIcon} />
        <h2>User Not Found</h2>
        <p>
          We are unable to locate your account <br />
          at this time.
        </p>
        <Button
          to="/login"
          component={Link}
          color="secondary"
          variant="contained"
          className={classes.button}
        >
          BACK TO LOGIN
        </Button>
      </Wrapper>
    </ContentWrapper>
  );
};

export default UserNotFound;

import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import Button from "@material-ui/core/Button";
import { createStyles, makeStyles } from "@material-ui/core";
import ContentWrapper from "@components/ContentWrapper";
import fontSizes from "@assets/styles/fontSizes";
import Lock from "@assets/images/lock_circle.svg";

const useStyles = makeStyles(theme =>
  createStyles({
    wrapper: {
      padding: "60px 80px",
      [theme.breakpoints.down("md")]: {
        padding: "15px 25px 15px 15px"
      }
    },
    button: {
      width: "100%",
      marginTop: 50
    },
    description: {
      marginTop: "15px",
      lineHeight: "175%",
      letterSpacing: "0.03em",
      fontSize: fontSizes.paragraph1
    }
  })
);

interface IProps {}

const Wrapper = styled.div`
  position: relative;
  text-align: center;
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-bottom: 15px;
`;

const AccountLock: React.FC<IProps> = () => {
  const classes = useStyles();
  const { pathname } = useLocation();

  const renderPermanentlyLockedContent = () => {
    return (
      <React.Fragment>
        <p>Your account has been permanently locked out.</p>
        <p>
          To reset your account you will need <br />
          to contact customer support.
        </p>
      </React.Fragment>
    );
  };

  const renderTemporaryLockedContent = () => {
    return (
      <React.Fragment>
        <p>
          Your account has been temporarily locked out <br />
          as a result of excessive bad login attempts.
        </p>
        <p>Please wait at least 30min before trying again.</p>
      </React.Fragment>
    );
  };

  return (
    <ContentWrapper>
      <Wrapper className={classes.wrapper}>
        <Logo src={Lock} />
        <h2>Account Lockout</h2>
        {pathname.indexOf("account-lock-perm") > -1 &&
          renderPermanentlyLockedContent()}
        {pathname.indexOf("account-lock-temp") > -1 &&
          renderTemporaryLockedContent()}
        <Button
          to="/login"
          component={Link}
          color="secondary"
          variant="contained"
          className={classes.button}
        >
          Ok
        </Button>
      </Wrapper>
    </ContentWrapper>
  );
};

export default AccountLock;

import React from "react";
import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import colors from "@assets/styles/colors";

interface IRecoveryFailureProps {}

const Main = styled.div`
  width: 400px;
  min-height: 520px;
  margin: 30px auto;
  background: ${colors.white};
  padding: 40px 83px;
`;

const Row = styled.div`
  display: flex;
`;

const Header = styled.div`
  padding: 30px 0px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const RecoveryFailure: React.FC<IRecoveryFailureProps> = () => {
  return (
    <Main>
      {/* <Icon f7="person_crop_circle_badge_xmark" size="120px" style="margin-top:30px;margin-bottom:50px;color:#C7CCD4"></Icon> */}
      <Row>
        <Column>
          <Header>Password Recovery Failure</Header>
        </Column>
      </Row>
      <Row>
        <Column>
          Your token is invalid or you failed to answer your password recovery
          question correctly.
        </Column>
      </Row>
      <Row>
        <Column>
          To reset your account you will need to contact customer support.
        </Column>
      </Row>
      <Row>
        <Column>
          <Link component={RouterLink} to="/login">
            Login
          </Link>
        </Column>
      </Row>
    </Main>
  );
};

export default RecoveryFailure;

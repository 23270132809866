import { action, computed, observable } from "mobx";

export class AppStore {
  // ---------
  // TODO: make interfaces
  // ---------
  private reg = localStorage.getItem("stitch_ui_reg");

  @observable public accessToken: string | null = null;
  @observable public isRegistered: boolean = this.reg
    ? JSON.parse(this.reg)
    : false;
  @observable public user: any = {};
  @observable public config: any = {
    flags: 2047,
    theme: "#FE8C61",
    logo: ""
  };

  @action public setAccessToken = (token: string) => {
    this.accessToken = token;
  };

  @action public setIsRegistered = (isRegistered: boolean) => {
    this.isRegistered = isRegistered;
    localStorage.setItem("stitch_ui_reg", JSON.stringify(isRegistered));
  };

  @action public setUser = (user: any) => {
    this.user = user;
  };

  @action public setConfig = (config: any) => {
    this.config = config;
  };

  @action public isFlag(flag: any): boolean {
    return this.config.flags && flag;
  }

  @computed public isLoggedOut(): boolean {
    return !this.accessToken;
  }

  @action public logout = () => {
    this.user = {};
    this.accessToken = null;
    this.isRegistered = false;
  };
}

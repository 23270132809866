import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import fontSizes from "@assets/styles/fontSizes";
import Failure from "@assets/images/failure.svg";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      textAlign: "center"
    },
    button: {
      width: "100%",
      marginTop: 50
    },
    description: {
      marginTop: "15px",
      lineHeight: "175%",
      letterSpacing: "0.03em",
      fontSize: fontSizes.paragraph1
    }
  })
);

interface IProps {}

const Logo = styled.img`
  width: 100px;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-bottom: 15px;
`;

const Success: React.FC<IProps> = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Logo src={Failure} />
      <h2>Account Closed</h2>
      <p className={classes.description}>
        Your account has been closed, <br />
        as you requested.
      </p>
      <Button
        to={"/login"}
        component={Link}
        color="secondary"
        variant="contained"
        className={classes.button}
      >
        Ok
      </Button>
    </div>
  );
};

export default Success;

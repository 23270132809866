import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import fontSizes from "@assets/styles/fontSizes";
import LogoHorizontal from "@assets/images/stitch_horizontal.png";

import ContentWrapper from "@components/ContentWrapper";

interface IProps {}

const Header = styled.div`
  padding: 30px 0;
`;

const Logo = styled.img`
  height: 28px;
  padding: 6px 0;
`;

const Text = styled.p`
  margin: 48px 0 20px 0;
  font-size: ${fontSizes.paragraph1};
`;

const IdQuiz: React.FC<IProps> = () => {
  // const [quiz, setQuiz] = useState<any>(null);
  // const [quizLoaded, setQuizLoaded] = useState<boolean>(false);
  const [quizLoaded] = useState<boolean>(false);

  return (
    <ContentWrapper>
      <React.Fragment>
        <Header>
          <Text>Identity Quiz</Text>
          <Logo src={LogoHorizontal} alt="" />
        </Header>
        {!quizLoaded && (
          <div>
            <Text>Please wait while we load your identity questions.</Text>
          </div>
        )}
        {quizLoaded && (
          <div>
            Load quiz here..
            <Button component={Link} to="#" color="primary" variant="contained">
              Verify Answers
            </Button>
          </div>
        )}
      </React.Fragment>
    </ContentWrapper>
  );
};

export default IdQuiz;

//<template>
//     <f7-page name="eid-quiz">
//         <div style="width:800px;margin:30px auto;background:#fff;padding:40px 83px;">
//             <f7-row style="padding:30px 0px">
//                 <f7-col><span style="font-weight:600;font-size:32px;line-height:32px;">Identity Quiz</span></f7-col>
//                 <f7-col class="text-align-right"><img :src="stitch_h" style="height:28px;padding:6px 0px"/></f7-col>
//             </f7-row>
//             <f7-row>
//                 <f7-col width="100" style="margin-top:40px" class="text-align-center" v-if="!quizLoaded">Please wait while we load your identity questions.</f7-col>
//             </f7-row>
//             <f7-row v-if="quizLoaded">
//                 <f7-col width="100">
//                     <form id="eidquiz" v-if="quizLoaded">
//                         <f7-list inset class="margin-half">
//                             <f7-list-item v-for="question in quiz.questions" :key="question.id">
//                                 <f7-block>
//                                 <b>{{question.text}}</b>
//                                 <f7-list class="no-margin">
//                                         <f7-list-item radio :name="question.id.toString()" :value="answer.id" v-for="answer in question.answers" :key="answer.id" required validate>{{answer.text}}</f7-list-item>
//                                 </f7-list>
//                                 </f7-block>
//                             </f7-list-item>
//                         </f7-list>
//                     </form>
//                 </f7-col>
//                 <f7-col width="100"><f7-button @click="verifyQuiz" fill style="margin:70px 0 10px 0;">Verify Answers</f7-button></f7-col>
//             </f7-row>
//         </div>
//   </f7-page>
// </template>
//<script>
//   import stitch_h from "../images/stitch_horizontal.png";
//
//   export default {
//     data() {
//       return {
//           stitch_h:stitch_h,
//           quizLoaded: false,
//           quiz: null
//       }
//     },
//     methods: {
//         startQuiz() {
//             this.$f7.preloader.show();
//             this.$stitch.getQuiz().then((data) => {
//                 console.log("eid Quiz Successful (maybe): ",data);
//                 this.quiz = data;
//                 this.quizLoaded = true;
//             })
//             .catch((error) => {
//                 console.log("Quiz Error: ",error);
//                 if(error.response.status == 428)
//                     this.$f7.dialog.alert("We need your personal registration information, let"s try again.", "Identification Failure", () => { this.$f7.dialog.close(); this.$f7router.navigate("/identity/"); });
//                 else if(error.response.status == 409)
//                     console.log("Error Identifying User - hence no questions");
//                 else
//                     this.$f7router.navigate("/eid-failed/");
//             })
//             .finally(() => { this.$f7.preloader.hide(); });
//         },
//         verifyQuiz(ans) {
//             this.$f7.input.validateInputs("#eidquiz");
//             if(this.$$("#eidquiz").find(".input-invalid").length > 0) {
//                 this.$f7.dialog.alert("Please correct the errors and try again", "Quiz Failure", () => { this.$f7.dialog.close(); });
//                 return;
//             }
//             var data = this.$f7.form.convertToData("#eidquiz");
//             console.log("eid Quiz: ", data);
//             let qr = {key: this.quiz.key, id: this.quiz.id, answers: []};
//             console.log("Quiz Resp: ",qr);
//             for(const q in data) { qr.answers.push({qid: parseInt(q), aid: parseInt(data[q])}) }
//             console.log("Full Quiz Resp: ",qr);
//
//             this.$f7.preloader.show();
//             this.$stitch.verifyQuiz(qr).then((data) => {
//                 console.log("eid Quiz Verified: ",data);
//
//                 this.$f7router.navigate(data.idpass ? "/id-success/":"/eid-failed/");
//             })
//             .catch((error) => {
//                 console.log("Quiz Error: ", error);
//                 if(error.response.status == 428)
//                     this.$f7.dialog.alert("We need your personal registration information, let"s try again.", "Identification Failure", () => { this.$f7.dialog.close(); this.$f7router.navigate("/identity/"); });
//                 else if(error.response.status == 409)
//                     this.$f7.dialog.alert("We were unable to accurately verify enough of your responses to continue.  A new set of questions has been provided, please try again.", "Quiz Failure", () => { document.getElementById("eidquiz").reset(); this.quiz = error.response.data; this.$f7.dialog.close(); });
//                 else if(error.response.status == 417)
//                     this.$f7.dialog.alert("We were unable to enroll your account for all features.  We will try again, but until then some features may not fully function.", "Enrollment Failure", () => { this.$f7.dialog.close(); this.$f7.views.main.router.navigate("/login/"); });
//                 else
//                     this.$f7router.navigate("/eid-failed/");
//             })
//             .finally(() => { this.$f7.preloader.hide(); });
//         },
//         cancel() {
//             this.$f7router.navigate("/");
//         },
//     },
//     mounted() {
//         this.startQuiz();
//     }
//   }
// </script>

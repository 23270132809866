import React from "react";
import ReactDOM from "react-dom";

import { IntlProvider } from "react-intl";
import translations, { Lang } from "@utils/locales";

import { SnackbarProvider } from "notistack";

import { ThemeProvider } from "@material-ui/core/styles";
import theme from "@utils/theme";

import App from "./App";
import Notifier from "./Notifier";

import "normalize.css";
import "./assets/styles/main.scss";

ReactDOM.render(
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <IntlProvider
        locale={Lang.EN}
        defaultLocale={Lang.EN}
        messages={translations[Lang.EN]}
      >
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Notifier />
          <App />
        </SnackbarProvider>
      </IntlProvider>
    </ThemeProvider>
  </React.Fragment>,
  document.getElementById("root")
);

import React from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import { NavLink } from "./NavLink";
import { Route } from "@utils/enums/routes";
import colors from "@assets/styles/colors";
import { Button } from "@material-ui/core";
import fontSizes from "@assets/styles/fontSizes";

interface IDrawerNavigationProps {
  toggleDrawer: Function;
  drawerOpen: boolean;
}

const useStyles = makeStyles(() => ({
  drawer: {
    width: "100%",
    height: "100%",
    flexShrink: 0,
    backgroundColor: colors.lightBackground
  },
  appbarBlank: {
    height: 90
  },
  drawerItem: {
    paddingTop: 16,
    paddingBottom: 16,
    fontWeight: "bold",
    fontSize: fontSizes.h3,
    justifyContent: "center"
  },
  bottomLink: {
    textTransform: "none",
    color: colors.lightPink
  }
}));

const DrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 32px;
`;

const DrawerLinksContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
  margin: 0px -8px;
`;

const DrawerNavigation: React.FC<IDrawerNavigationProps> = ({
  toggleDrawer,
  drawerOpen
}) => {
  const classes = useStyles();
  const { formatMessage: t } = useIntl();

  const signOut = () => {};

  return (
    <Drawer
      anchor={"top"}
      className={classes.drawer}
      classes={{
        paper: classes.drawer
      }}
      open={drawerOpen}
      onClose={toggleDrawer(false)}
    >
      <Toolbar className={classes.appbarBlank} />
      <DrawerContainer
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <DrawerLinksContainer>
          <List>
            <ListItem
              button
              component={NavLink}
              className={classes.drawerItem}
              to={`/${Route.SETTINGS}`}
            >
              {t({ id: "common.settings" })}
            </ListItem>
            <ListItem
              button
              className={classes.drawerItem}
              component={NavLink}
              to={`/${Route.ABOUT}`}
            >
              {t({ id: "common.about" })}
            </ListItem>
            <ListItem button className={classes.drawerItem} onClick={signOut}>
              {t({ id: "common.sign_out" })}
            </ListItem>
          </List>
        </DrawerLinksContainer>
        <Divider />
        <Bottom>
          <Button
            no
            className={classes.bottomLink}
            component={NavLink}
            to={`/${Route.TERMS_OF_USE}`}
          >
            Terms of Use
          </Button>
          <Button
            className={classes.bottomLink}
            component={NavLink}
            to={`/${Route.PRIVACY_POLICY}`}
          >
            Privacy Policy
          </Button>
        </Bottom>
      </DrawerContainer>
    </Drawer>
  );
};

export default DrawerNavigation;

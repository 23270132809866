import React, { useState } from "react";
import styled from "styled-components";
import { Link as L } from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Button, TextField } from "@material-ui/core";
import colors from "@assets/styles/colors";
import fontSizes from "@assets/styles/fontSizes";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      maxWidth: 300,
      "& > *": {
        margin: theme.spacing(1),
        width: "100%"
      }
    },
    submit: {
      marginTop: 100
    },
    description: {
      lineHeight: "175%",
      letterSpacing: "0.03em",
      fontSize: fontSizes.paragraph1
    }
  })
);

interface IProps {
  handleSubmit: any;
}

const CancelButton = styled(L)`
  display: block;
  text-align: center;
  text-decoration: none;
  color: ${colors.lightPink};
  font-size: ${fontSizes.paragraph1};
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const NewPassword: React.FC<IProps> = ({ handleSubmit }) => {
  const classes = useStyles();
  const [newPass, setNewPass] = useState<string>("");
  const [confirmPass, setConfirmPass] = useState<string>("");

  const changePass = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (newPass === confirmPass) handleSubmit(newPass);
  };

  return (
    <React.Fragment>
      <Header>
        <h1>Change Password</h1>
      </Header>
      <form className={classes.root} onSubmit={changePass} color="primary">
        <p className={classes.description}>Please enter your new password.</p>
        <TextField
          required
          id="password"
          type="password"
          value={newPass}
          color="secondary"
          label="New Password"
          placeholder="Enter your new password"
          onInput={e => {
            const target = e.target as HTMLInputElement;
            setNewPass(target.value);
          }}
        />
        <TextField
          required
          type="password"
          color="secondary"
          value={confirmPass}
          id="confirm_password"
          label="Re-write new password"
          placeholder="Re-write your new password"
          onInput={e => {
            const target = e.target as HTMLInputElement;
            setConfirmPass(target.value);
          }}
        />
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          className={classes.submit}
        >
          Change
        </Button>
        <CancelButton to={"/settings"}>Cancel</CancelButton>
      </form>
    </React.Fragment>
  );
};

export default NewPassword;

export default {
  start: "https://efx-dev.stitchcredit.com/api/users/start",
  // start: "/users/start?key=dc1e6f28-90c2-4c02-adcd-d2badf32c02a",
  initialize: "/users/initialize",
  login: "/users/login",
  register: "/users/register",
  users: "/users",
  identity: "/users/identity",
  quiz: "/users/get-quiz",
  verifyQuiz: "/users/verify-quiz",
  verifyPhone: "/users/get-mobile",
  sendCode: "/users/send-code",
  sendNewCode: "/users/renew-code",
  verifyCode: "/users/verify-code",
  verifyEnrollment: "/users/enrollment-check",
  efxConfig: "/users/efx-config",
  scoreLatest: "/v1/creditScore/latest",
  scoreHistory: "/v1/creditScore/history",
  reportLatest: "/v1/creditReport/latest",
  reportList: "/v1/creditReport",
  reportSummary: (reportId: string | number) =>
    `/v1/creditReport/${reportId}/summary`,
  creditMonitoring: "/v1/creditMonitoring"
};

// import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import fontSizes from "@assets/styles/fontSizes";
import colors from "@assets/styles/colors";

interface INavLinkProps {
  isActive?: boolean;
}

export const NavLink = styled(Link)<INavLinkProps>`
  font-size: ${fontSizes.h4};
  line-height: 16px;
  text-decoration: none;
  color: ${({ isActive }) => (isActive ? colors.lightPink : colors.lightBlack)};
  margin-right: 60px;
`;

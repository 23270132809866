import React from "react";
import styled from "styled-components";
import { Link as L } from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Button, TextField } from "@material-ui/core";
import colors from "@assets/styles/colors";
import fontSizes from "@assets/styles/fontSizes";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
        width: "100%"
      }
    },
    submit: {
      marginTop: 100
    },
    description: {
      lineHeight: "175%",
      letterSpacing: "0.03em",
      fontSize: fontSizes.paragraph1
    }
  })
);

interface IProps {
  password: string;
  handleSubmit: any;
  setPassword: Function;
}

const CancelButton = styled(L)`
  display: block;
  text-align: center;
  text-decoration: none;
  color: ${colors.lightPink};
  font-size: ${fontSizes.paragraph1};
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const CurrentPassword: React.FC<IProps> = ({
  password,
  setPassword,
  handleSubmit
}) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Header>
        <h1>
          Change Recovery <br />
          Question
        </h1>
      </Header>
      <form className={classes.root} onSubmit={handleSubmit} color="primary">
        <p className={classes.description}>
          Please enter your current password.
        </p>
        <TextField
          required
          id="password"
          type="password"
          label="Password"
          value={password}
          color="secondary"
          placeholder="Enter your current password"
          onInput={e => {
            const target = e.target as HTMLInputElement;
            setPassword(target.value);
          }}
        />
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          className={classes.submit}
        >
          Continue
        </Button>
        <CancelButton to={"/settings"}>Cancel</CancelButton>
      </form>
    </React.Fragment>
  );
};

export default CurrentPassword;

export default [
  {
    id: "yudhamahn-001",
    type: "NAME",
    category: null,
    name: "NAME ALERT",
    description:
      "The name on your credit file has changed. If you did not initiate this change it could be a warning sign of identity theft.",
    dateReported: 1453657876620,
    provider: "EFX"
  },
  {
    id: "yudhamahn-002",
    type: "INQUIRY",
    category: null,
    name: "INQUIRY ALERT",
    description: "BLOCKBUSTER INQUIRY",
    dateReported: 1420866000000,
    provider: "EXP"
  },
  {
    id: "yudhamahn-003",
    type: "ADDRESS",
    category: null,
    name: "ADDRESS ALERT",
    description:
      "The primary address listed on your credit file has been changed. If you did not change your address, this could be a warning sign of identity theft.",
    dateReported: null,
    provider: "EFX"
  },
  {
    id: "yudhamahn-004",
    type: "ADDRESS",
    category: null,
    name: "ADDRESS ALERT",
    description:
      "The primary address listed on your credit file has been changed. If you did not change your address, this could be a warning sign of identity theft.",
    dateReported: null,
    provider: "TU"
  },
  {
    id: "yudhamahn-005",
    type: "ADDRESS",
    category: null,
    name: "ADDRESS ALERT",
    description:
      "The primary address listed on your credit file has been changed. If you did not change your address, this could be a warning sign of identity theft.",
    dateReported: null,
    provider: "EXP"
  },
  {
    id: "yudhamahn-006",
    type: "BALANCEAMOUNT",
    category: null,
    name: "BALANCEAMOUNT ALERT",
    description:
      "Your account has a balance increase that exceeds the limit set in your Alert Preferences. If you did not initiate this increase, contact your creditor.",
    dateReported: 1083384000000,
    provider: "EFX"
  },
  {
    id: "yudhamahn-007",
    type: "BALANCEPERCENT",
    category: null,
    name: "BALANCEPERCENT ALERT",
    description:
      "Your account has a balance increase that exceeds the limit set in your Alert Preferences. If you did not initiate this increase, contact your creditor.",
    dateReported: 1083384000000,
    provider: "EFX"
  },
  {
    id: "yudhamahn-008",
    type: "ACCOUNTNUMBERCHANGE",
    category: null,
    name: "ACCOUNTNUMBERCHANGE ALERT",
    description:
      "There has been a change to an existing account, such as the following: 1. Rate/Status - indicates your current status on each account. if you are current and pay as agreed or if you are late, which can affect your credit score 2. Comments on the Account - indicates if the account is closed, if the account is being disputed, or the type of account it is.",
    dateReported: 1083384000000,
    provider: "EFX"
  },
  {
    id: "yudhamahn-009",
    type: "ACCOUNTNUMBERCHANGE",
    category: null,
    name: "ACCOUNTNUMBERCHANGE ALERT",
    description:
      "There has been a change to an existing account, such as the following: 1. Rate/Status - indicates your current status on each account. if you are current and pay as agreed or if you are late, which can affect your credit score 2. Comments on the Account - indicates if the account is closed, if the account is being disputed, or the type of account it is.",
    dateReported: 1083384000000,
    provider: "TU"
  },
  {
    id: "yudhamahn-010",
    type: "ACCOUNTNUMBERCHANGE",
    category: null,
    name: "ACCOUNTNUMBERCHANGE ALERT",
    description:
      "There has been a change to an existing account, such as the following: 1. Rate/Status - indicates your current status on each account. if you are current and pay as agreed or if you are late, which can affect your credit score 2. Comments on the Account - indicates if the account is closed, if the account is being disputed, or the type of account it is.",
    dateReported: 1083384000000,
    provider: "EXP"
  },
  {
    id: "yudhamahn-011",
    type: "TRADELINE",
    category: null,
    name: "TRADELINE ALERT",
    description:
      "A new account has been opened in your name. If you did not authorize this account, contact the creditor.",
    dateReported: 1083384000000,
    provider: "EFX"
  },
  {
    id: "yudhamahn-012",
    type: "TRADELINE",
    category: null,
    name: "TRADELINE ALERT",
    description:
      "A new account has been opened in your name. If you did not authorize this account, contact the creditor.",
    dateReported: 1083384000000,
    provider: "TU"
  },
  {
    id: "yudhamahn-013",
    type: "TRADELINE",
    category: null,
    name: "TRADELINE ALERT",
    description:
      "A new account has been opened in your name. If you did not authorize this account, contact the creditor.",
    dateReported: 1083384000000,
    provider: "EXP"
  },
  {
    id: "yudhamahn-014",
    type: "INQUIRY",
    category: null,
    name: "INQUIRY ALERT",
    description: "BLOCKBUSTER INQUIRY",
    dateReported: 1420866000000,
    provider: "EFX"
  },
  {
    id: "yudhamahn-015",
    type: "INQUIRY",
    category: null,
    name: "INQUIRY ALERT",
    description: "BLOCKBUSTER INQUIRY",
    dateReported: 1420866000000,
    provider: "TU"
  },
  {
    id: "yudhamahn-016",
    type: "INQUIRY",
    category: null,
    name: "INQUIRY ALERT",
    description: "BLOCKBUSTER INQUIRY",
    dateReported: 1420866000000,
    provider: "EXP"
  },
  {
    id: "yudhamahn-017",
    type: "DORMANTMONTHS",
    category: null,
    name: "DORMANTMONTHS ALERT",
    description:
      "Your account was considered dormant but has been used recently. The time between this most current and the last activity date for this account exceeds the limit set in your Alert Preferences. If you did not initiate this activity, contact your creditor.",
    dateReported: 1083384000000,
    provider: "EFX"
  }
];

import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import { createStyles, makeStyles } from "@material-ui/core";
import ContentWrapper from "@components/ContentWrapper";
import fontSizes from "@assets/styles/fontSizes";
import colors from "@assets/styles/colors";
import LogoSrc from "@assets/images/logo.png";

const useStyles = makeStyles(theme =>
  createStyles({
    wrapper: {
      width: 500,
      [theme.breakpoints.down("md")]: {
        width: 300
      }
    },
    contentWrapper: {
      padding: "25px 40px",
      [theme.breakpoints.down("md")]: {
        padding: "15px"
      }
    }
  })
);

interface IProps {}

const Wrapper = styled.div`
  position: relative;
`;

const Link = styled.a`
  width: 100%;
  text-align: center;
  color: ${colors.lightPink};
  font-size: ${fontSizes.paragraph1};
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.gray};

  > :last-of-type {
    text-align: right;
  }
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  padding: 30px 0;
  justify-items: center;
  border-top: 1px solid ${colors.gray};
`;

const Iframe = styled.iframe`
  width: 100%;
  border: none;
  height: 300px;
  overflow: hidden;
`;

const Logo = styled.img`
  height: 25px;
  padding: 6px 0;
`;

const PrivacyPolicy: React.FC<IProps> = () => {
  const classes = useStyles();
  const { goBack } = useHistory();

  return (
    <ContentWrapper>
      <Wrapper className={classes.wrapper}>
        <Header className={classes.contentWrapper}>
          <Logo src={LogoSrc} />
          <Link
            href="#"
            onClick={() => {
              goBack();
            }}
          >
            Back
          </Link>
        </Header>
        <Iframe
          id="pp-iframe"
          src="https://app.termly.io/embed/terms-of-use/958f774c-a642-41e7-9696-7ac060bdf44c"
        />
        <Footer>
          <Link href="#" onClick={() => {}}>
            Download
          </Link>
        </Footer>
      </Wrapper>
    </ContentWrapper>
  );
};

export default PrivacyPolicy;

import React, { useEffect, useState } from "react";
import Container from "@components/Container";

import Monitoring from "@components/Monitoring";

// TODO: Remove when API ready
import monitoringData from "@utils/mocks/monitoring";
// import monitoringData from "@utils/mocks/monitoring-single";

interface IProps {}

const Monitor: React.FC<IProps> = () => {
  const [title, setTitle] = useState<string>("");
  const [multipleSources, setMultipleSources] = useState<boolean>(false);

  useEffect(() => {
    const multi =
      monitoringData
        .map(d => d.provider)
        .filter((item, i, ar) => ar.indexOf(item) === i).length >= 2;

    if (multi) {
      setMultipleSources(true);
      setTitle("Monitoring");
    } else {
      setMultipleSources(false);
      setTitle(`Monitoring - ${monitoringData[0].provider}`);
    }
  }, []);

  return (
    <Container>
      <React.Fragment>
        <h1>{title}</h1>
        <div>
          {monitoringData.map(m => (
            <Monitoring data={m} key={m.id} multi={multipleSources} />
          ))}
        </div>
      </React.Fragment>
    </Container>
  );
};

export default Monitor;

export default {
  black: "#000000",
  white: "#ffffff",
  background: "#E2ECF3",
  lightBackground: "#EEF1F8",
  lightPink: "#EF4D7E",
  lightBlack: "#211F1F",
  gray: "#ECEDF0",
  violet: "#6B2F73",
  supportive: "#90929C",
  footer: "#6B6F75"
};

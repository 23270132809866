import React from "react";
import { Route } from "@utils/enums/routes";

import Home from "@routes/home";
import Settings from "@routes/settings";
import PasswordRecovery from "@routes/password_recovery";
import RecoveryFailure from "@routes/recovery_failure";
import PasswordReset from "@routes/password_reset";
import About from "@routes/about";
import RegistrationFail from "@routes/register_fail";
import NewAccount from "@routes/new_account";
import Identity from "@routes/identity";
import RegisterLock from "@routes/register_lock";
import CloseAccount from "@routes/close_account";
import AccountClosed from "@routes/account_closed";
import IdQuiz from "@routes/id_quiz";
import EIdFailed from "@routes/eid_failed";
import IdSuccess from "@routes/id_success";
import NotFound from "@routes/not_found";
import SysError from "@routes/sys_error";
import Menu from "@routes/menu";
import TermsOfUse from "@routes/terms_of_use";
import PrivacyPolicy from "@routes/privacy_policy";
import ScoreFactory from "@routes/score_factory";
import Mobile from "@routes/mobile";
import Score from "@routes/score";
import Report from "@routes/report";
import Monitor from "@routes/monitor";
import IdRestore from "@routes/id_restore";
import Login from "@routes/login";
import Register from "@routes/register";
import AccountLock from "@routes/account_lock";
import UserNotFound from "@routes/user_not_found";
import ChangePassword from "@routes/change_password";
import ChangeEmail from "@routes/change_email";
import ChangeRecoveryQuestion from "@routes/change_recovery_question";

export interface IRouteProps {
  component: React.FC;
  path: string | string[];
  exact?: boolean;
}

export const privateRoutes: IRouteProps[] = [
  // Dashboard pages
  {
    path: `/${Route.DASHBOARD_SCORE}`,
    component: Score,
    exact: true
  },
  {
    path: `/${Route.DASHBOARD_REPORT}`,
    component: Report
  },
  {
    path: `/${Route.DASHBOARD_MONITOR}`,
    component: Monitor
  },
  {
    path: `/${Route.DASHBOARD_IDRESTORE}`,
    component: IdRestore
  },
  // END // Dashboard pages
  {
    path: `/${Route.SETTINGS}`,
    component: Settings
  },
  {
    path: `/${Route.ABOUT}`,
    component: About
  },
  {
    path: `/${Route.IDENTITY}`,
    component: Identity
  },
  {
    path: `/${Route.CLOSE_ACCOUNT}`,
    component: CloseAccount
  },
  {
    path: `/${Route.ACCOUNT_CLOSED}`,
    component: AccountClosed
  },
  {
    path: `/${Route.MOBILE}`,
    component: Mobile
  },
  {
    path: `/${Route.EID}`,
    component: IdQuiz
  },
  {
    path: `/${Route.EID_FAILED}`,
    component: EIdFailed
  },
  {
    path: `/${Route.ID_SUCCESS}`,
    component: IdSuccess
  },
  {
    path: `/${Route.ID_RESTORE}`,
    component: IdRestore
  },
  {
    path: `/${Route.MENU}`,
    component: Menu
  },
  {
    path: `/${Route.SCORE_FACTORY}`,
    component: ScoreFactory
  },
  {
    path: `/${Route.CHANGE_PASSWORD}`,
    component: ChangePassword
  },
  {
    path: `/${Route.CHANGE_EMAIL}`,
    component: ChangeEmail
  },
  {
    path: `/${Route.CHANGE_RECOVERY_QUESTION}`,
    component: ChangeRecoveryQuestion
  }
];

export const publicRoutes: IRouteProps[] = [
  {
    path: `/`,
    exact: true,
    component: Home
  },
  {
    path: `/${Route.LOGIN}`,
    component: Login
  },
  {
    path: `/${Route.PASSWORD_RESET}`,
    component: PasswordReset
  },
  {
    path: `/${Route.SIGNUP}`,
    exact: true,
    component: Register
  },
  {
    path: `/${Route.NEW_ACCOUNT}`,
    component: NewAccount
  },
  {
    path: `/${Route.REGISTRATION_FAIL}`,
    component: RegistrationFail
  },
  {
    path: `/${Route.PASSWORD_RECOVERY}`,
    component: PasswordRecovery
  },
  {
    path: `/${Route.RECOVERY_FAILURE}`,
    component: RecoveryFailure
  },
  {
    path: `/${Route.ACCOUNT_LOCK_TEMP}`,
    component: AccountLock
  },
  {
    path: `/${Route.ACCOUNT_LOCK_PERM}`,
    component: AccountLock
  },
  {
    path: `/${Route.TERMS_OF_USE}`,
    component: TermsOfUse
  },
  {
    path: `/${Route.PRIVACY_POLICY}`,
    component: PrivacyPolicy
  },
  {
    path: `/${Route.REGISTER_LOCK}`,
    component: RegisterLock
  },
  {
    path: `/${Route.REGISTER_IP_LOCK}`,
    component: RegisterLock
  },
  {
    path: `/${Route.SYS_ERROR}`,
    component: SysError
  },
  {
    path: `/${Route.USER_NOT_FOUND}`,
    component: UserNotFound
  },
  {
    path: `/${Route.NOT_FOUND}`,
    component: NotFound
  }
  // {
  //   path: "*",
  //   component: NotFound
  // }
];

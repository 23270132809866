import React from "react";
import styled from "styled-components";
import { createStyles, makeStyles } from "@material-ui/core";
import ContentWrapper from "@components/ContentWrapper";
import fontSizes from "@assets/styles/fontSizes";

const useStyles = makeStyles(theme =>
  createStyles({
    wrapper: {
      padding: "60px 80px",
      [theme.breakpoints.down("md")]: {
        padding: "15px 25px 15px 15px"
      }
    },
    button: {
      width: "100%",
      marginTop: 50
    },
    description: {
      marginTop: "15px",
      lineHeight: "175%",
      letterSpacing: "0.03em",
      fontSize: fontSizes.paragraph1
    }
  })
);

interface IProps {}

const Wrapper = styled.div`
  position: relative;
  text-align: center;
`;

const About: React.FC<IProps> = () => {
  const classes = useStyles();

  return (
    <ContentWrapper>
      <Wrapper className={classes.wrapper}>
        <h1>StitchCredit - Credit Score Widget</h1>
        <p>
          Fugiat perspiciatis excepturi, soluta quod ullam deleniti. <br />
          Nobis sint nemo consequuntur. Eius perferendis animi autem <br />
          incidunt quod tenetur nostrum, voluptate omnis quasi quidem <br />
          illum consequuntur, a, quisquam.
        </p>
        <p>
          Fugiat perspiciatis excepturi, soluta quod ullam deleniti. <br />
          Nobis sint nemo consequuntur. Eius perferendis animi autem <br />
          incidunt quod tenetur nostrum, voluptate omnis quasi quidem <br />
          illum consequuntur, a, quisquam.
        </p>
      </Wrapper>
    </ContentWrapper>
  );
};

export default About;

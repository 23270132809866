import { action, observable } from "mobx";
import { SnackbarMessage, OptionsObject, SnackbarKey } from "notistack";

interface INotificationObject {
  message: SnackbarMessage;
  options?: OptionsObject;
}

interface INotificationSnack extends INotificationObject {
  key: SnackbarKey;
}

// const NotifyStore = function() {
export class NotifyStore {
  @observable public notifications: INotificationSnack[] = [];

  @action public snack = (notificationObject: INotificationObject) => {
    this.notifications.push({
      key: new Date().getTime() + Math.random(),
      ...notificationObject
    });
  };

  @action public error = (message: string) => {
    this.notifications.push({
      key: new Date().getTime() + Math.random(),
      message: message,
      options: {
        variant: "error"
      }
    });
  };

  @action public info = (message: string) => {
    this.notifications.push({
      key: new Date().getTime() + Math.random(),
      message: message,
      options: {
        variant: "info"
      }
    });
  };

  @action public success = (message: string) => {
    this.notifications.push({
      key: new Date().getTime() + Math.random(),
      message: message,
      options: {
        variant: "success"
      }
    });
  };

  @action public removeSnackbar = (notificationKey: SnackbarKey) => {
    // this.notifications.remove(notificationKey);
    this.notifications = this.notifications.filter(
      (snack: INotificationSnack) => snack.key !== notificationKey
    );
  };
}

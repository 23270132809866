import React, { useState } from "react";
import styled from "styled-components";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Button, TextField } from "@material-ui/core";
import fontSizes from "@assets/styles/fontSizes";
import LogoSrc from "@assets/images/logo.png";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
        width: "100%"
      }
    },
    submit: {
      marginTop: 100
    },
    description: {
      lineHeight: "175%",
      letterSpacing: "0.03em",
      fontSize: fontSizes.paragraph1
    }
  })
);

interface IProps {
  nextStep: Function;
}

const Logo = styled.img`
  height: 25px;
  padding: 6px 0;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const SecretQuestion: React.FC<IProps> = ({ nextStep }) => {
  const classes = useStyles();
  const [secretAnswer, setSecretAnswer] = useState<string>("");

  const handleSubmit = () => {
    // console.log("secretAnswer", secretAnswer);
    nextStep();
  };

  return (
    <React.Fragment>
      <Header>
        <h1>
          Forgot
          <br />
          Password
        </h1>
        <Logo src={LogoSrc} />
      </Header>
      <form className={classes.root} onSubmit={handleSubmit} color="primary">
        <p className={classes.description}>
          Please answer your password <br />
          recovery question.
        </p>
        <TextField
          required
          type="text"
          id="secretAnswer"
          color="secondary"
          value={secretAnswer}
          label="Secret question ..??"
          placeholder="Enter your answer"
          onInput={e => {
            const target = e.target as HTMLInputElement;
            setSecretAnswer(target.value);
          }}
        />
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          className={classes.submit}
        >
          CONTINUE
        </Button>
      </form>
    </React.Fragment>
  );
};

export default SecretQuestion;

import { action, observable } from "mobx";
import {
  userLogin,
  registerUser,
  identityUser,
  getUser,
  getQuiz,
  verifyQuiz,
  verifyPhone,
  sendCode,
  sendNewCode,
  verifyCode,
  verifyEnrollment,
  getDeliveryToken,
  recoverPassword,
  validateResetToken,
  resetPassword,
  closeAccount
} from "@api/";
import { AppStore, NotifyStore } from ".";

export class UserStore {
  constructor(private appStore: AppStore, private notify: NotifyStore) {}

  @observable public userIdentity: any = {};
  @observable public quiz: any = {};

  @action public setUserIdentity = (userIdentity: any) => {
    this.userIdentity = userIdentity;
  };

  @action public setQuiz = (quiz: any) => {
    this.quiz = quiz;
  };

  @action public userLogin = async (username: string, password: string) => {
    try {
      const response = await userLogin(username, password);
      this.appStore.setAccessToken(response.data.token);
    } catch (error) {
      this.notify.error(error);
    }
  };

  @action public registerUser = async (data: any) => {
    try {
      const response = await registerUser(data);
      // this.setIsRegistered(true);
      this.appStore.setAccessToken(response.data.token);
    } catch (error) {
      this.notify.error(error);
    }
  };

  @action public identityUser = async (data: any) => {
    try {
      const response = await identityUser(data);
      this.setUserIdentity(response.data);
    } catch (error) {
      this.notify.error(error);
    }
  };

  @action public getUser = async () => {
    try {
      const response = await getUser();
      this.appStore.setUser(response.data);
    } catch (error) {
      this.notify.error(error);
    }
  };

  @action public getQuiz = async () => {
    try {
      const response = await getQuiz();
      this.setQuiz(response.data);
    } catch (error) {
      this.notify.error(error);
    }
  };

  @action public verifyQuiz = async (data: any) => {
    try {
      const response = await verifyQuiz(data);
      return response.data;
    } catch (error) {
      this.notify.error(error);
    }
  };
  @action public verifyPhone = async () => {
    this.notify.info("Verifying Phone...");
    try {
      const response = await verifyPhone();
      return response.data;
    } catch (error) {
      this.notify.error(error);
    }
  };

  @action public sendCode = async () => {
    try {
      const response = await sendCode();
      this.notify.success("Code sent successfuly");
      return response.data;
    } catch (error) {
      this.notify.error(error);
    }
  };
  @action public sendNewCode = async (data: any) => {
    try {
      const response = await sendNewCode(data);
      this.notify.success("New code sent successfuly");
      return response.data;
    } catch (error) {
      this.notify.error(error);
    }
  };

  @action public verifyCode = async (data: any) => {
    try {
      const response = await verifyCode(data);
      this.notify.success("Phone verified!");
      return response.data;
    } catch (error) {
      this.notify.error(error);
    }
  };

  @action public verifyEnrollment = async () => {
    try {
      const response = await verifyEnrollment();
      this.notify.success("message!");
      return response.data;
    } catch (error) {
      this.notify.error(error);
    }
  };

  @action public recoverPassword = async (data: any) => {
    try {
      const response = await recoverPassword(data);
      this.notify.success("Phone verified!");
      return response;
    } catch (error) {
      this.notify.error(error);
    }
  };

  @action public validateResetToken = async (data: any) => {
    try {
      const response = await validateResetToken(data);
      this.notify.success("Phone verified!");
      return response;
    } catch (error) {
      this.notify.error(error);
    }
  };

  @action public resetPassword = async (data: any) => {
    try {
      const response = await resetPassword(data);
      this.notify.success("Phone verified!");
      return response;
    } catch (error) {
      this.notify.error(error);
    }
  };

  @action public closeAccount = async (data: any) => {
    try {
      const response = await closeAccount(data);
      this.notify.success("Phone verified!");
      this.appStore.logout();
      return response;
    } catch (error) {
      this.notify.error(error);
    }
  };

  @action public getDeliveryToken = async (count: number = 1) => {
    try {
      const response = await getDeliveryToken(count);
      this.notify.success("message!");
      return response;
    } catch (error) {
      this.notify.error(error);
    }
  };
}

import React from "react";
import styled from "styled-components";
import colors from "@assets/styles/colors";

interface IReportProps {}

const Main = styled.div`
  width: 400px;
  min-height: 520px;
  margin: 30px auto;
  background: ${colors.white};
  padding: 40px 83px;
`;

const Row = styled.div`
  display: flex;
`;

const Header = styled.div`
  padding: 30px 0px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Block = styled.div``;
const List = styled.div``;

const Report: React.FC<IReportProps> = () => {
  const refreshReport = () => {
    // this.$f7.preloader.show();
    // this.$stitch.getReportList().then((data) => {
    //     if(data && data.length > 0) {
    //         let reportId = data.sort((a,b) => a.generatedDate > b.generatedDate ? 1 : (a.generatedDate === b.generatedDate) ? ((a.reportType === "US_3B") ?  1 : -1) : -1 )[0].id;
    //         return this.$stitch.getReportSummary(reportId).then((rep) => {
    //             Object.assign(this.reports, efxutil._processSummary(rep));
    //             this.bureaus = Object.keys(this.reports);
    //         });
    //     }
    //     return Promise.resolve();
    // })
    // .catch((error) => {
    //     console.error("Efx Reports Error: ",error);
    //     if(this.isLoggedOut())
    //         this.$f7router.navigate("/login/");
    // })
    // .finally(() => { this.$f7.preloader.hide(); });
  };
  refreshReport();
  return (
    <Main>
      <Header>
        StitchCredit: Report Summary
        <div>util.names[bureau]</div>
      </Header>
      <Block>
        <p>
          Review this summary for a quick view of key information contained in
          your Equifax Credit Report, as well as your resulting Equifax Credit
          Score and rating.
        </p>
        <Row>
          <Column>Report Date</Column>
          <Column>reports[bureau].date.toLocaleDateString()</Column>
        </Row>
        <Row>
          <Column>Average Account Age</Column>
          <Column>
            Math.trunc(reports[bureau].additional.avgAccountAge/12) Years,
            reports[bureau].additional.avgAccountAge%12 Months
          </Column>
        </Row>
        <Row>
          <Column>Oldest Account</Column>
          <Column>
            reports[bureau].additional.oldestAccount |
            reports[bureau].additional.oldestAccountDate.toLocaleDateString()
          </Column>
        </Row>
      </Block>
      <Block>Credit Score and Rating</Block>
      <Block>
        <p>
          Your credit score and rating are not part of your credit report, but
          are derived from the information in your file.
        </p>
        <Row>
          <Column>Credit Score</Column>
          <Column>reports[bureau].score</Column>
        </Row>
        <Row>
          <Column>Score Rating</Column>
          <Column>reports[bureau].rating</Column>
        </Row>

        <p></p>
        <Row>
          <Column>
            <Block>Positive Factors</Block>
          </Column>
          <Column>
            <Block>Negative Factors</Block>
          </Column>
        </Row>
        <Row>
          <Column>
            <List>
              <ul>
                <li>factor.description</li>
              </ul>
            </List>
          </Column>
          <Column>
            <List>
              <ul>
                <li>factor.description</li>
              </ul>
            </List>
          </Column>
        </Row>
      </Block>
      <Block>Credit Accounts</Block>
      <Block>
        <p>
          Your credit report includes information about activity on your credit
          accounts that affect your credit score and rating. The table below is
          an overview of your current accounts and status.
        </p>
        <Row>
          <Column>Account&nbsp;Type</Column>
          <Column>Open</Column>
          <Column>With&nbsp;Balance</Column>
          <Column>Total&nbsp;Balance</Column>
          <Column>Available</Column>
          <Column>Credit&nbsp;Limit</Column>
          <Column>
            <div>Debt-to-Credit</div>
          </Column>
          <Column>Payment</Column>
        </Row>
        <Row>
          <Column>acc.title</Column>
          <Column>acc.open</Column>
          <Column>acc.openWithBalance</Column>
          <Column>
            acc.balance ? "$" + acc.balance.toLocaleString() : "n/a"
          </Column>
          <Column>acc.avail ? "$" + acc.avail.toLocaleString() : "n/a"</Column>
          <Column>acc.limit ? "$" + acc.limit.toLocaleString() : "n/a"</Column>
          <Column>acc.debtRatio ? acc.debtRatio + "%": "n/a"</Column>
          <Column>
            acc.paymentAmt ? "$" + acc.paymentAmt.toLocaleString() : "n/a"
          </Column>
        </Row>
      </Block>
      <Block>Other Credit Items</Block>
      <Block>
        <p>
          Your credit report includes information about instances of non-account
          items that may affect your credit score and rating. The table below is
          a summary of non-account related items on your report.
        </p>
        <Row>
          <Column>Consumer Statements</Column>
          <Column>reports[bureau].additional.consumer Statements Found</Column>
        </Row>
        <Row>
          <Column>Personal Information</Column>
          <Column>reports[bureau].additional.personal Items Found</Column>
        </Row>
        <Row>
          <Column>Inquiries</Column>
          <Column>reports[bureau].additional.inquiries Inquiries Found</Column>
        </Row>
        <Row>
          <Column>Public Records</Column>
          <Column>reports[bureau].additional.public Records Found</Column>
        </Row>
        <Row>
          <Column>Collections</Column>
          <Column>
            reports[bureau].additional.collections Collections Found
          </Column>
        </Row>
      </Block>
    </Main>
  );
};

export default Report;

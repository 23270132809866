import React from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { Link as RouterLink } from "react-router-dom";
import { Route } from "@utils/enums/routes";
import colors from "@assets/styles/colors";

interface INewAccountProps {}

const Form = styled.div`
  width: 400px;
  min-height: 520px;
  margin: 30px auto;
  background: ${colors.white};
  padding: 40px 83px;
`;

const Header = styled.div`
  padding: 30px 0px;
`;

const Row = styled.div`
  display: flex;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const List = styled.div``;

const Input = styled.input``;

const Link = styled(RouterLink)``;

const Title = styled.span`
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
`;

const HeaderImg = styled.img`
  height: 28px;
  padding: 6px 0px;
`;

const Label = styled.label``;

const ButtonContainer = styled.div`
  margin-top: 35px;
`;

const LoginContainer = styled.div`
  margin-top: 35px;
  margin-bottom: 35px;
`;

const DescriptionContainer = styled.div`
  text-align: justify;
  text-justify: inter-word;
`;

const SelectInput = styled.select``;

const NewAccount: React.FC<INewAccountProps> = () => {
  const register = () => {
    // if (invalidInput.length > 0) {
    //   // valid inputs
    //   return alert(
    //     "Please correct the errors and try again",
    //     "New Account Error"
    //   );
    // }
    // apiCall
    //   .registerUser(data)
    //   .then(() => {
    //     // navigate("/identity/");
    //   })
    //   .catch(error => {
    //     alert(
    //       "We were unable to create an account for you.  The email you used is most likely already registered.",
    //       "New Account Error"
    //     );
    //   });
  };

  return (
    <Form>
      <Header>
        <Column className="text-align-left">
          <Title>Registration</Title>
        </Column>
        <Column className="text-align-right">
          <HeaderImg />
        </Column>
      </Header>
      <Row>
        <Column>
          <List>
            <Label>First Name</Label>
            <Input
              type="text"
              placeholder="First Name"
              error-message="Your first name is required"
              name="fname"
              floating-label
              required
              // validate
            />
          </List>
        </Column>
        <Column>
          <List>
            <Label>Last Name</Label>
            <Input
              type="text"
              placeholder="Last Name"
              error-message="Your last name is required"
              name="lname"
              floating-label
              required
              // validate
            />
          </List>
        </Column>
      </Row>
      <Row>
        <List>
          <Label>E-mail</Label>
          <Input
            type="email"
            placeholder="E-mail"
            error-message="E-mail address required"
            name="email"
            floating-label
            required
            // validate
          />
          <Label>Password</Label>
          <Input
            type="password"
            placeholder="Password"
            error-message="Password requirements: 1 upper case letter, 1 lower case letter, 1 number, 8 characters min"
            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
            name="password"
            floating-label
            required
            // validate
          />

          <Label>Password Recovery Question</Label>
          <SelectInput
            placeholder="Please choose..."
            error-message="A Password recovery question is required"
            name="recoveryQuestion"
            floating-label
            required
            // validate
          >
            <option value="" />
            <option value="1">What Is your favorite book?</option>
            <option value="2">
              What is the name of the road you grew up on?
            </option>
            <option value="3">What is your mother’s maiden name?</option>
            <option value="4">
              What was the name of your first/current/favorite pet?
            </option>
            <option value="5">
              What was the first company that you worked for?
            </option>
            <option value="6">Where did you meet your spouse?</option>
            <option value="7">Where did you go to high school/college?</option>
            <option value="8">What is your favorite food?</option>
            <option value="9">What city were you born in?</option>
            <option value="10">
              Where is your favorite place to vacation?
            </option>
          </SelectInput>

          <Label>Password Recovery Answer</Label>
          <Input
            type="text"
            placeholder="Password Recovery Answer"
            error-message="An answer to your password recovery question is required"
            name="recoveryAnswer"
            floating-label
            required
            // validate
          />
        </List>
      </Row>
      <Row>
        <ButtonContainer>
          <Button onClick={register}>Register</Button>
        </ButtonContainer>
      </Row>
      <Row>
        <LoginContainer>
          <Button component={Link} to="/login">
            Log In!
          </Button>
        </LoginContainer>
      </Row>
      <Row>
        <DescriptionContainer>
          By clicking "Register” and creating an account you accept
          StitchCredit’s
          <Link to={`/${Route.TERMS_OF_USE}`}>Terms of Use</Link> and{" "}
          <Link to={`/${Route.PRIVACY_POLICY}`}>Privacy Policy</Link>.
          StitchCredit does not maintain critical personal data, much less sell
          or otherwise disclose your personal information to anyone else. You
          may opt-out of email correspondence, except confirmation Emails, which
          often contain important information about your account.
        </DescriptionContainer>
      </Row>
    </Form>
  );
};

export default NewAccount;

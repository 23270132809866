import React from "react";

interface IScoreProps {}

const Score: React.FC<IScoreProps> = () => {
  // const refreshScore = () => {
  //   // this.$f7.preloader.show();
  //   // this.$stitch.getDeliveryToken().then(() => {
  //   //     let sc = this.$stitch.getScoreLatest().then((data) => {
  //   //         Object.assign(this.scores, efxutil._processScores(data));
  //   //     })
  //   //     .catch((error) => { console.log("EFX Latest Error: ",error) });
  //   //     let sh = this.$stitch.getScoreHistory().then((data) => {
  //   //         Object.assign(this.history, efxutil._processScoreHistory(data));
  //   //     })
  //   //     .catch((error) => { console.log("EFX Latest Error: ",error) });
  //   //     /*
  //   //     let rp = this.$stitch.getReportLatest().then((data) => {
  //   //         Object.assign(this.reports, efxutil._processReports(data.providerViews));
  //   //     })
  //   //     .catch((error) => {
  //   //         console.log("Efx Reports Error: ",error);
  //   //     });
  //   //     */
  //   //     let rl = this.$stitch.getReportList().then((data) => {
  //   //         if(data && data.length > 0) {
  //   //             let reportId = data.sort((a,b) => a.generatedDate > b.generatedDate ? 1 : (a.generatedDate === b.generatedDate) ? ((a.reportType === "US_3B") ?  1 : -1) : -1 )[0].id;
  //   //             return this.$stitch.getReportSummary(reportId).then((rep) => {
  //   //                 Object.assign(this.reports, efxutil._processSummary(rep));
  //   //             });
  //   //         }
  //   //         return Promise.resolve();
  //   //     })
  //   //     .catch((error) => {
  //   //         console.log("Efx Reports Error: ",error);
  //   //     });
  //   //     return Promise.all([sc,sh,rl]).then(()=>{
  //   //         this.bureaus = Object.keys(this.reports);
  //   //         setTimeout(()=>{
  //   //             Object.keys(this.scores).forEach((bureau) => {
  //   //                 if(this.scores[bureau].score && this.scores[bureau].rating)
  //   //                     this._renderScoreGraph("#"+bureau.toLowerCase()+"-score-graph", this.scores[bureau].score, this.scores[bureau].rating);
  //   //             })
  //   //         });
  //   //     });
  //   // })
  //   // .catch((error) => {
  //   //     if(this.isLoggedOut())
  //   //         this.$f7router.navigate("/login/");
  //   // })
  //   // .finally(() => { this.$f7.preloader.hide(); });
  //   // _renderScoreGraph();
  //   // isGreaterScore(1, 2);
  //   // isLowerScore(1, 2);
  //   // isEqualScore(1, 2);
  // };
  // const isGreaterScore = (score: any, lastScore: any) => {
  //   return lastScore === 280 ? false : score > lastScore;
  // };
  // const isLowerScore = (score: any, lastScore: any) => {
  //   return lastScore === 280 ? false : score < lastScore;
  // };
  // const isEqualScore = (score: any, lastScore: any) => {
  //   return lastScore === 280 ? false : score === lastScore;
  // };
  // const _renderScoreGraph = () => {
  //   // _renderArc(target, score, rating);
  //   // _renderScore(target, score);
  //   // _renderRating(target, rating);
  //   // _renderRanges(target);
  // };
  // const _renderArc = () => {
  //   // ani.drawArc(this.$$, this.$$(target)[0], {
  //   // value:       score,
  //   // minValue:    this.scoreConfig.MIN_SCORE,
  //   // maxValue:    this.scoreConfig.MAX_SCORE,
  //   // circleClass: "graph__arc",
  //   // arcClass:    "graph__arc graph__arc--" + rating.replace(/\s+/g, "-").toLowerCase()
  //   // });
  // };
  // const _renderScore = () => {
  //   // ani.drawLabel(this.$$, this.$$(target)[0], score, {
  //   // "class": "rlp-credit-score__number",
  //   // "yOffset" : 0.05,
  //   // "scale"   : 0.28
  //   // })
  // };
  // const _renderRating = () => {
  //   // ani.drawLabel(this.$$, this.$$(target)[0], rating, {
  //   // "class"   : "rlp-credit-score__rating",
  //   // "yOffset" : 0.20,
  //   // "scale"   : 0.075
  //   // })
  // };
  // const _renderRanges = () => {
  //   // ani.drawLabel(this.$$, this.$$(target)[0], this.scoreConfig.MIN_SCORE, {
  //   // "class"   : "rlp-credit-score__ranges",
  //   // "xOffset" : -0.22,
  //   // "yOffset" : 0.40,
  //   // "scale"   : 0.075
  //   // })
  //   // ani.drawLabel(this.$$, this.$$(target)[0], this.scoreConfig.MAX_SCORE, {
  //   // "class"   : "rlp-credit-score__ranges",
  //   // "xOffset" : 0.22,
  //   // "yOffset" : 0.40,
  //   // "scale"   : 0.075
  //   // })
  // };
  //
  // refreshScore();

  return <div>Score</div>;
};

export default Score;

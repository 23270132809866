import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import {
  privateRoutes,
  IRouteProps,
  publicRoutes
} from "@utils/constants/routes";
import { Route as RouteConstants } from "@utils/enums/routes";
import Home from "@routes/home";
import PrivateLayout from "@components/PrivateLayout";

// import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
//
// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       flexGrow: 1,
//       maxWidth: 1200,
//       margin: "0 auto"
//     }
//   })
// );

const fakeAuth = {
  isAuthenticated: true
};

const AuthRoute = ({ ...rest }) =>
  fakeAuth.isAuthenticated ? (
    <PrivateLayout>
      <Route {...rest} />
    </PrivateLayout>
  ) : (
    <Redirect to={`/${RouteConstants.LOGIN}`} />
  );

const App: React.FC = () => {
  return (
    <Router>
      {/* NAV */}
      <Switch>
        <Route exact path={"/"} component={Home} />
        {/*<AuthRoute*/}
        {/*path="/"*/}
        {/*exact={true}*/}
        {/*component={() => {*/}
        {/*const pathname = `/${RouteConstants.DASHBOARD_SCORE}`;*/}
        {/*return <Redirect to={{ pathname }} />;*/}
        {/*}}*/}
        {/*/>*/}
        {privateRoutes.map(({ ...rest }: IRouteProps, i: number) => (
          <AuthRoute key={`private-route-${i}`} {...rest} />
        ))}
        {publicRoutes.map(({ ...rest }, i: number) => (
          <Route {...rest} key={`public-route-${i}`} />
        ))}
      </Switch>
    </Router>
  );
};

export default App;

import React from "react";

interface IScoreFactoryProps {}

const ScoreFactory: React.FC<IScoreFactoryProps> = () => {
  return (
    <div>
      <div>Positive Factor</div>
      <div>Negative Factors</div>
      positive
      <ul>
        <li>factor.description</li>
      </ul>
      negative
      <ul>
        <li>factor.description</li>
      </ul>
    </div>
  );
};

export default ScoreFactory;

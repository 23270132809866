import { createMuiTheme } from "@material-ui/core/styles";
import colors from "@assets/styles/colors";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.white
    },
    secondary: {
      main: colors.lightPink
    },
    error: {
      main: colors.lightPink
    }
  }
});

export default theme;

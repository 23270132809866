import React, { useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";

import Hidden from "@material-ui/core/Hidden";

import Button from "@material-ui/core/Button";
import Logo from "@assets/images/logo.png";
import { Route } from "@utils/enums/routes";
import ButtonNavigation from "./Navigation/ButtonNavigation";
import { NavLink } from "./Navigation/NavLink";
import DrawerNavigation from "./Navigation/DrawerNavigation";

interface IPrivateLayoutProps {}

const StitchLogo = styled.img`
  height: 32px;
`;

const LogoContainer = styled.div`
  flex-grow: 1;
`;

const useStyles = makeStyles(theme => ({
  // root: {
  //   flexGrow: 1,
  //   padding: "0 120px",
  //   [theme.breakpoints.down("md")]: {
  //     padding: "0 32px"
  //   }
  // },
  children: {
    height: "calc(100% - 90px)"
  },
  appbar: {
    height: 90,
    padding: "0 120px",
    [theme.breakpoints.down("md")]: {
      padding: "0 32px"
    }
  },
  appbarBlank: {
    height: 90
  },
  zIndex: {
    zIndex: 1301
  },
  title: {
    flexGrow: 1
  }
}));

const PrivateLayout: React.FC<IPrivateLayoutProps> = ({ children }) => {
  const classes = useStyles();
  const { formatMessage: t } = useIntl();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  let url = useLocation();

  const signOut = () => {};

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <>
      <AppBar elevation={0} className={classes.zIndex}>
        <Toolbar className={classes.appbar}>
          <LogoContainer>
            <StitchLogo src={Logo} />
          </LogoContainer>
          <Hidden smDown>
            <NavLink
              isActive={url.pathname === `/${Route.DASHBOARD_SCORE}`}
              to={`/${Route.DASHBOARD_SCORE}`}
            >
              {t({ id: "common.score" })}
            </NavLink>
            <NavLink
              isActive={url.pathname.indexOf(Route.DASHBOARD_REPORT) > -1}
              to={`/${Route.DASHBOARD_REPORT}`}
            >
              {t({ id: "common.reports" })}
            </NavLink>
            <NavLink
              isActive={url.pathname.indexOf(Route.DASHBOARD_MONITOR) > -1}
              to={`/${Route.DASHBOARD_MONITOR}`}
            >
              {t({ id: "common.monitoring" })}
            </NavLink>
            <NavLink
              isActive={url.pathname.indexOf(Route.DASHBOARD_IDRESTORE) > -1}
              to={`/${Route.DASHBOARD_IDRESTORE}`}
            >
              {t({ id: "common.id_restore" })}
            </NavLink>
            <NavLink
              isActive={url.pathname.indexOf(Route.SETTINGS) > -1}
              to={`/${Route.SETTINGS}`}
            >
              {t({ id: "common.settings" })}
            </NavLink>
            <NavLink
              isActive={url.pathname.indexOf(Route.ABOUT) > -1}
              to={`/${Route.ABOUT}`}
            >
              {t({ id: "common.about" })}
            </NavLink>
            <Button onClick={signOut} variant="outlined">
              {t({ id: "common.sign_out" })}
            </Button>
          </Hidden>
          <Hidden mdUp>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={toggleDrawer(!drawerOpen)}
            >
              {drawerOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
      <Hidden mdUp>
        <DrawerNavigation drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />
      </Hidden>
      <Hidden mdUp>
        <ButtonNavigation />
      </Hidden>
      <Toolbar className={classes.appbarBlank} />
      <div className={classes.children}>{children}</div>
    </>
  );
};

export default PrivateLayout;

import React, { useState } from "react";
import styled from "styled-components";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import ContentWrapper from "@components/ContentWrapper";
import CurrentPassword from "@components/ChangePassword/CurrentPassword";
import NewPassword from "@components/ChangePassword/NewPassword";
import Success from "@components/ChangePassword/Success";

const useStyles = makeStyles(theme =>
  createStyles({
    wrapper: {
      padding: "60px 80px",
      [theme.breakpoints.down("md")]: {
        padding: "15px 25px 15px 15px"
      }
    }
  })
);

interface IProps {}

const Wrapper = styled.div`
  position: relative;
`;

const ChangePassword: React.FC<IProps> = () => {
  const classes = useStyles();
  const [step, setStep] = useState<number>(0);
  const [currentPassword, setCurrentPassword] = useState<string>("");

  const checkPassword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // console.log(email);

    nextStep();
  };

  const changePassword = (newPass: string) => {
    // console.log(newPass);

    nextStep();
  };

  const nextStep = () => {
    // setTimeout(() => {
    setStep(step + 1);
    // }, 2000);
  };

  const renderContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <CurrentPassword
            password={currentPassword}
            handleSubmit={checkPassword}
            setPassword={setCurrentPassword}
          />
        );
      case 1:
        return <NewPassword handleSubmit={changePassword} />;
      case 2:
        return <Success />;
    }
  };

  return (
    <ContentWrapper>
      <Wrapper className={classes.wrapper}>
        <div>{renderContent(step)}</div>
      </Wrapper>
    </ContentWrapper>
  );
};

export default ChangePassword;

import React from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import ScoreIcon from "@material-ui/icons/NetworkCheck";
import ReportsIcon from "@material-ui/icons/IndeterminateCheckBoxSharp";
import MonitoringIcon from "@material-ui/icons/NotificationsSharp";
import IDRestoreIcon from "@material-ui/icons/Autorenew";
import colors from "@assets/styles/colors";
import { Route } from "@utils/enums/routes";
import { NavLink } from "./NavLink";

interface IButtonNavigationProps {}

const useStyles = makeStyles(() => ({
  bottomNavigation: {
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
    height: 74,
    backgroundColor: colors.lightBackground
  },
  activeItem: {
    color: colors.lightPink
  }
}));

const ButtonNavigation: React.FC<IButtonNavigationProps> = () => {
  const classes = useStyles();
  const { formatMessage: t } = useIntl();
  let url = useLocation();

  return (
    <BottomNavigation
      // value={value}
      // onChange={(event, newValue) => {
      //   setValue(newValue);
      // }}
      showLabels
      className={classes.bottomNavigation}
    >
      <BottomNavigationAction
        label={t({ id: "common.score" })}
        component={NavLink}
        to={`/${Route.DASHBOARD_SCORE}`}
        icon={<ScoreIcon />}
        className={
          url.pathname === `/${Route.DASHBOARD_SCORE}` ? classes.activeItem : ""
        }
      />
      <BottomNavigationAction
        label={t({ id: "common.reports" })}
        component={NavLink}
        to={`/${Route.DASHBOARD_REPORT}`}
        icon={<ReportsIcon />}
        className={
          url.pathname.indexOf(Route.DASHBOARD_REPORT) > -1
            ? classes.activeItem
            : ""
        }
      />
      <BottomNavigationAction
        label={t({ id: "common.monitoring" })}
        component={NavLink}
        to={`/${Route.DASHBOARD_MONITOR}`}
        icon={<MonitoringIcon />}
        className={
          url.pathname.indexOf(Route.DASHBOARD_MONITOR) > -1
            ? classes.activeItem
            : ""
        }
      />
      <BottomNavigationAction
        label={t({ id: "common.id_restore" })}
        component={NavLink}
        to={`/${Route.DASHBOARD_IDRESTORE}`}
        icon={<IDRestoreIcon />}
        className={
          url.pathname.indexOf(Route.DASHBOARD_IDRESTORE) > -1
            ? classes.activeItem
            : ""
        }
      />
    </BottomNavigation>
  );
};

export default ButtonNavigation;

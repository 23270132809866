import React from "react";
import moment from "moment";
import styled from "styled-components";
import colors from "@assets/styles/colors";
import ICreditMonitoring from "@interfaces/creditMonitoring";
import fontSizes from "@assets/styles/fontSizes";

interface IProps {
  data: ICreditMonitoring;
  multi: boolean;
}

const Wrapper = styled.div`
  display: flex;
  background: white;
  padding: 15px 25px;
  border-radius: 4px;
  margin-bottom: 10px;
  flex-direction: column;
`;

const TitleRow = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 10px;
  border-bottom: 1px solid ${colors.gray};
  justify-items: space-between;

  > div {
    width: 100%;

    &:last-of-type {
      text-align: right;
    }
  }
`;

const Description = styled.p`
  margin-bottom: 0;
  color: ${colors.supportive};
  font-size: ${fontSizes.paragraph2};
`;

const Date = styled.span`
  font-weight: 300;
  color: ${colors.footer};
`;

const Monitoring: React.FC<IProps> = ({ data, multi }) => {
  const prefix = multi ? `${data.provider} - ` : "";

  return (
    <Wrapper>
      <TitleRow>
        <div>
          {prefix}
          {data.name}
        </div>
        <div>
          {data.dateReported && (
            <Date>{moment(data.dateReported).format("MM/DD/YYYY")}</Date>
          )}
          {!data.dateReported && <Date>N/A</Date>}
        </div>
      </TitleRow>
      <div>
        <Description>{data.description}</Description>
      </div>
    </Wrapper>
  );
};

export default Monitoring;

import React from "react";

interface IProps {}

const Identity: React.FC<IProps> = () => {
  return <div>Identity</div>;
};

export default Identity;

//<template>
//   <f7-page name="identity">
//     <form className="form-store-data text-align-center" id="stitch-id" style="width:400px;min-height:520px;margin: 30px auto;background:#fff;padding:40px 83px;">
//       <f7-row style="padding:30px 0px">
//         <f7-col className="text-align-left"><span style="font-weight:600;font-size: 32px;line-height: 32px;">Identity</span></f7-col>
//         <f7-col className="text-align-right"><img :src="stitch_h" style="height:28px;padding:6px 0px"/></f7-col>
//       </f7-row>
//       <f7-row>
//         <f7-col>
//           <f7-list no-hairlines-md className="no-margin-vertical">
//             <f7-list-input
//               label="Social Security Number"
//               type="text"
//               placeholder="SSN"
//               error-message="Your social security number is required"
//               name="ssn"
//               pattern="\d{3}[\-]?\d{2}[\-]?\d{4}"
//               floating-label
//               required validate
//             ></f7-list-input>
//           </f7-list>
//         </f7-col>
//         <f7-col>
//           <f7-list no-hairlines-md class="no-margin-vertical">
//             <f7-list-input
//               label="Date of Birth"
//               type="date"
//               error-message="Your date of birth is required"
//               name="dob"
//               required validate
//             ></f7-list-input>
//           </f7-list>
//         </f7-col>
//       </f7-row>
//       <f7-row>
//         <f7-col>
//           <f7-list no-hairlines-md class="no-margin-vertical">
//             <f7-list-input
//               label="Mobile Phone"
//               type="tel"
//               placeholder="ex. 555-555-1212"
//               name="mobile"
//               pattern="(?:\d{3}|\(\d{3}\))[- ]?\d{3}[- ]?\d{4}"
//               floating-label
//             ></f7-list-input>
//           </f7-list>
//         </f7-col>
//       </f7-row>
//       <f7-row>
//         <f7-col>
//           <f7-list no-hairlines-md class="no-margin-vertical">
//             <f7-list-input
//               label="Street Address 1"
//               type="text"
//               placeholder="Street Address 1"
//               error-message="Your street address is required"
//               name="street1"
//               floating-label
//               required validate
//             ></f7-list-input>
//
//             <f7-list-input
//               label="Street Address 2"
//               type="text"
//               placeholder="Street Address 2"
//               name="street2"
//               floating-label
//             ></f7-list-input>
//           </f7-list>
//         </f7-col>
//       </f7-row>
//       <f7-row>
//         <f7-col width="50">
//           <f7-list no-hairlines-md class="no-margin-vertical">
//             <f7-list-input
//               label="City"
//               type="text"
//               placeholder="City"
//               error-message="Your address city is required"
//               name="city"
//               floating-label
//               required validate
//             ></f7-list-input>
//           </f7-list>
//         </f7-col>
//         <f7-col width="25">
//           <f7-list no-hairlines-md class="no-margin-vertical">
//             <f7-list-input
//               label="State"
//               type="select"
//               placeholder="Select your State"
//               error-message="Your address state is required"
//               name="state"
//               floating-label
//               required validate
//             >
//               <option value=""></option>
//               <option v-for="(state,abbr) in states" :key="abbr" :value="abbr">{{state}}</option>
//             </f7-list-input>
//           </f7-list>
//         </f7-col>
//         <f7-col width="25">
//           <f7-list no-hairlines-md class="no-margin-vertical">
//             <f7-list-input
//               label="Zipcode"
//               type="text"
//               placeholder="ex 55555 or 55555-5555"
//               error-message="Your address zipcode is required"
//               name="zip"
//               pattern="\d{5}([\-]\d{4})?"
//               floating-label
//               required validate
//             ></f7-list-input>
//           </f7-list>
//         </f7-col>
//       </f7-row>
//       <f7-row>
//         <f7-col style="text-align:justify;text-justify:inter-word;margin-top:35px;">
//             You understand that by clicking "Continue", you are explicitly agreeing to and providing "written instructions" to StitchCredit under the Fair
//             Credit Reporting Act to obtain my credit information from one or more of the three nationwide credit reporting agencies. Third-party sources, including your mobile carrier may be
//             used to verify your identity. You authorize StitchCredit to obtain such information for you to confirm your identity, and, for as long as
//             you are a member of StitchCredit, to provide you with your credit information. You may elect to terminate your account and this authorization at any time.
//         </f7-col>
//       </f7-row>
//       <f7-row>
//         <f7-col style="margin-top:35px;margin-bottom:35px;"><f7-button @click="identify" fill raised class="col">Continue</f7-button></f7-col>
//       </f7-row>
//     </form>
//   </f7-page>
// </template>
// <script>
//   import { mapMutations } from "vuex";
//   import stitch_h from "../images/stitch_horizontal.png";
//
//   let states = {AL:"Alabama",AK:"Alaska",AZ:"Arizona",AR:"Arkansas",CA:"California",CO:"Colorado",CT:"Connecticut",DE:"Delaware",FL:"Florida",GA:"Georgia",HI:"Hawaii",ID:"Idaho",IL:"Illinois",IN:"Indiana",IA:"Iowa",KS:"Kansas",KY:"Kentucky",LA:"Louisiana",ME:"Maine",MD:"Maryland",MA:"Massachusetts",MI:"Michigan",MN:"Minnesota",MS:"Mississippi",MO:"Missouri",MT:"Montana",NE:"Nebraska",NV:"Nevada",NH:"New Hampshire",NJ:"New Jersey",NM:"New Mexico",NY:"New York",NC:"North Carolina",ND:"North Dakota",OH:"Ohio",OK:"Oklahoma",OR:"Oregon",PA:"Pennsylvania",RI:"Rhode Island",SC:"South Carolina",SD:"South Dakota",TN:"Tennessee",TX:"Texas",UT:"Utah",VT:"Vermont",VA:"Virginia",WA:"Washington",WV:"West Virginia",WI:"Wisconsin",WY:"Wyoming"};
//
//   export default {
//     data() {
//       return {
//         stitch_h: stitch_h,
//         states: states
//       }
//     },
//     methods: {
//       identify() {
//         this.$f7.input.validateInputs("#stitch-id");
//         if(this.$$("#stitch-id").find(".input-invalid").length > 0) {
//           this.$f7.dialog.alert("Please correct the errors and try again", "Identity Failure", () => { this.$f7.dialog.close(); });
//           return;
//         }
//
//         var data = this.$f7.form.getFormData("#stitch-id");
//         data.mobile = data.mobile.replace(/[\D]/g, "");
//         data.ssn = data.ssn.replace(/[\D]/g, "");
//         console.log("Form Data: ",data);
//
//         this.$stitch.identityUser(data).then(() => {
//           this.$f7router.navigate("/mobile/");
//         })
//         .catch((error) => {
//           this.$f7.dialog.alert("Identity Failed.  User is most likely already registered.", "Identity Failure", () => { this.$f7.dialog.close(); });
//         });
//       },
//       cancel() {
//         //this.$f7.loginScreen.open("#my-login-screen",false);
//         this.ACCESS_TOKEN(null);
//         this.$f7router.navigate("/");
//       },
//       ...mapMutations(["ACCESS_TOKEN"])
//     },
//     mounted() {
//
//     }
//   }
// </script>

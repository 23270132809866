import { createContext } from "react";
import { AppStore, UserStore, NotifyStore } from ".";

const appStore = new AppStore();
const notifyStore = new NotifyStore();

const userStore = new UserStore(appStore, notifyStore);

export const storesContext = createContext({
  appStore,
  userStore,
  notifyStore
});

import React, { useState } from "react";
import styled from "styled-components";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import ContentWrapper from "@components/ContentWrapper";
import CurrentPassword from "@components/ChangeRecoveryQuestion/CurrentPassword";
import RecoveryQuestion from "@components/ChangeRecoveryQuestion/RecoveryQuestion";
import NewRecoveryQuestion from "@components/ChangeRecoveryQuestion/NewRecoveryQuestion";
import NewRecoveryQuestionAnswer from "@components/ChangeRecoveryQuestion/NewRecoveryQuestionAnswer";
import Success from "@components/ChangeRecoveryQuestion/Success";

const useStyles = makeStyles(theme =>
  createStyles({
    wrapper: {
      padding: "60px 80px",
      [theme.breakpoints.down("md")]: {
        padding: "15px 25px 15px 15px"
      }
    }
  })
);

interface IProps {}

const Wrapper = styled.div`
  position: relative;
`;

const ChangeRecoveryQuestion: React.FC<IProps> = () => {
  const classes = useStyles();
  const [step, setStep] = useState<number>(0);
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [recQuestion, setRecQuestion] = useState<string>("");
  const [question, setQuestion] = useState<string>("");
  const [newRecQuestion, setNewRecQuestion] = useState<string>("");

  const checkPassword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    nextStep();
  };

  const checkRecQuestion = () => {
    nextStep();
  };

  const changeQuestion = () => {
    nextStep();
  };

  const submitNewRecQuestionAnswer = () => {
    nextStep();
  };

  const nextStep = () => {
    // setTimeout(() => {
    setStep(step + 1);
    // }, 2000);
  };

  const renderContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <CurrentPassword
            password={currentPassword}
            handleSubmit={checkPassword}
            setPassword={setCurrentPassword}
          />
        );
      case 1:
        return (
          <RecoveryQuestion
            recQuestion={recQuestion}
            setRecQuestion={setRecQuestion}
            handleSubmit={checkRecQuestion}
          />
        );
      case 2:
        return (
          <NewRecoveryQuestion
            question={question}
            setQuestion={setQuestion}
            handleSubmit={changeQuestion}
          />
        );
      case 3:
        return (
          <NewRecoveryQuestionAnswer
            recQuestion={newRecQuestion}
            setRecQuestion={setNewRecQuestion}
            handleSubmit={submitNewRecQuestionAnswer}
          />
        );
      case 4:
        return <Success />;
    }
  };

  return (
    <ContentWrapper>
      <Wrapper className={classes.wrapper}>
        <div>{renderContent(step)}</div>
      </Wrapper>
    </ContentWrapper>
  );
};

export default ChangeRecoveryQuestion;

import React, { useState } from "react";
import styled from "styled-components";
import { Link as L } from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ContentWrapper from "@components/ContentWrapper";

import { Button, TextField } from "@material-ui/core";

import colors from "@assets/styles/colors";
import fontSizes from "@assets/styles/fontSizes";
import LogoSrc from "@assets/images/logo.png";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
        width: "100%"
      }
    },
    wrapper: {
      padding: "60px 80px",
      [theme.breakpoints.down("md")]: {
        padding: "15px 25px 15px 15px"
      }
    },
    submit: {
      marginTop: 100
    },
    signUp: {
      marginTop: 10,
      textAlign: "center",
      fontSize: fontSizes.paragraph2
    }
  })
);

interface IProps {}

const Wrapper = styled.div`
  position: relative;
`;

const Logo = styled.img`
  height: 25px;
  padding: 6px 0;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ForgotPass = styled(L)`
  display: block;
  text-align: right;
  color: ${colors.lightPink};
  font-size: ${fontSizes.paragraph2};
`;

const SignUp = styled(L)`
  color: ${colors.lightPink};
`;

const Login: React.FC<IProps> = () => {
  const classes = useStyles();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // console.log(email, password);
  };

  return (
    <ContentWrapper>
      <Wrapper className={classes.wrapper}>
        <Header>
          <h1>Login</h1>
          <Logo src={LogoSrc} />
        </Header>
        <div>
          <form
            className={classes.root}
            onSubmit={handleSubmit}
            color="primary"
          >
            <TextField
              required
              id="email"
              type="email"
              label="Email"
              value={email}
              color="secondary"
              placeholder="Type your email"
              onInput={e => {
                const target = e.target as HTMLInputElement;
                setEmail(target.value);
              }}
            />
            <TextField
              required
              id="password"
              type="password"
              label="Password"
              value={password}
              color="secondary"
              placeholder="Type your password"
              onInput={e => {
                const target = e.target as HTMLInputElement;
                setPassword(target.value);
              }}
            />
            <ForgotPass to={"/password-recovery"}>Forgot password?</ForgotPass>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              className={classes.submit}
            >
              Login
            </Button>
            <p className={classes.signUp}>
              Don’t have an account? <SignUp to={"/register"}>Sign Up</SignUp>
            </p>
          </form>
        </div>
      </Wrapper>
    </ContentWrapper>
  );
};

export default Login;

import React, { useEffect } from "react";
import { start } from "@api/";
import { useHistory } from "react-router";

interface IProps {}

const Home: React.FC<IProps> = () => {
  const { push } = useHistory();

  useEffect(() => {
    async function init() {
      try {
        await start();
        push("/login");
      } catch (e) {
        if (e && e.response) {
          push("/reg-fail");
        } else {
          push("/sys-error");
        }
      }
    }

    init();
  }, [push]);

  return <></>;
};

export default Home;

import React from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import fontSizes from "@assets/styles/fontSizes";
import NCheck from "@assets/images/n-check.svg";
import { Link } from "react-router-dom";

import ContentWrapper from "@components/ContentWrapper";

interface IProps {}

const Title = styled.h3`
  font-weight: 600;
  font-size: ${fontSizes.h3};
`;

const Text = styled.p`
  margin: 48px 0 20px 0;
  font-size: ${fontSizes.paragraph1};
`;

const Check = styled.img`
  margin: 40px auto;
`;

const IdSuccess: React.FC<IProps> = () => {
  return (
    <ContentWrapper>
      <React.Fragment>
        <Check src={NCheck} alt="" />
        <Title>Welcome</Title>

        <div>
          <Text>You have signed up successfully.</Text>
          <Text>Thank you for signing up to Stitch Credit.</Text>
        </div>

        <Button
          component={Link}
          to="/dashboard/"
          color="primary"
          variant="contained"
        >
          Continue
        </Button>
      </React.Fragment>
    </ContentWrapper>
  );
};

export default IdSuccess;

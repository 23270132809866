import api, { sAxios, eAxios } from "@utils/api";
import endpoints from "@utils/constants/endpoints";

const RETRY_COUNT = 3;

export const start = () => api({ url: endpoints.start });

export const initialize = () => api({ url: endpoints.initialize });

export const userLogin = (username: string, password: string) =>
  api({
    method: "POST",
    url: endpoints.login,
    data: { username, password }
  });

export const registerUser = (data: any) =>
  api({
    url: endpoints.register,
    method: "POST",
    data: data
  });

export const identityUser = (data: any) =>
  sAxios({
    url: endpoints.identity,
    method: "POST",
    data: data
  });

export const getUser = () => sAxios({ url: endpoints.users });

export const getQuiz = () => sAxios({ url: endpoints.quiz });

export const verifyQuiz = (data: any) =>
  sAxios({
    url: endpoints.verifyQuiz,
    method: "POST",
    data: data
  });

export const verifyPhone = () => sAxios({ url: endpoints.verifyPhone });

export const sendCode = () => sAxios({ url: endpoints.sendCode });

export const sendNewCode = (data: any) =>
  sAxios({
    url: endpoints.sendNewCode,
    method: "POST",
    data: data
  });

export const verifyCode = (data: any) =>
  sAxios({
    url: endpoints.verifyCode,
    method: "POST",
    data: data
  });

export const verifyEnrollment = () =>
  sAxios({
    url: endpoints.verifyEnrollment,
    method: "POST"
  });

export const recoverPassword = (data: any) => Promise.resolve(1);
export const validateResetToken = (data: any) => Promise.resolve(1);
export const resetPassword = (data: any) => Promise.resolve(1);

export const closeAccount = (data: any) => Promise.resolve(1);

export const getDeliveryToken = (count: number = 1) => {
  if (eAxios.defaults.headers.Authorization && eAxios.defaults.baseURL)
    return Promise.resolve();

  return sAxios({ url: endpoints.efxConfig }).then(resp => {
    eAxios.defaults.baseURL = resp.data.url;
    return api({
      url: resp.data.url + "/oauth/token",
      method: "post",
      params: {
        scope: "delivery",
        grant_type: "jwt-bearer",
        api_key: resp.data.id,
        client_assertion: resp.data.secret
      }
    })
      .then(response => {
        eAxios.defaults.headers.Authorization =
          "Bearer " + response.data.access_token;
      })
      .catch(error => {
        if (!error.response || error.response.status === 401) {
          // TODO: LOGOUT LOGIC HERE
          // this.logout();
          throw error;
        }
      });
  });
};

export const getScoreLatest = (count: number = 1): any => {
  if (!(eAxios.defaults.headers.Authorization && eAxios.defaults.baseURL)) {
    return getDeliveryToken().then(() => {
      return getScoreLatest();
    });
  }

  return eAxios({ url: endpoints.scoreLatest, method: "get" })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return handleEFXError(error, count++, getScoreLatest);
    });
};

export const getScoreHistory = (count: number = 1): any => {
  if (!(eAxios.defaults.headers.Authorization && eAxios.defaults.baseURL)) {
    return getDeliveryToken().then(() => {
      return getScoreHistory();
    });
  }

  return eAxios({ url: endpoints.scoreHistory, method: "get" })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return handleEFXError(error, count++, getScoreHistory);
    });
};

export const getReportLatest = (count: number = 1): any => {
  if (!(eAxios.defaults.headers.Authorization && eAxios.defaults.baseURL)) {
    return getDeliveryToken().then(() => {
      return getReportLatest();
    });
  }

  return eAxios({ url: endpoints.reportLatest, method: "get" })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return handleEFXError(error, count++, getReportLatest);
    });
};

export const getReportList = (count: number = 1): any => {
  if (!(eAxios.defaults.headers.Authorization && eAxios.defaults.baseURL)) {
    return getDeliveryToken().then(() => {
      return getReportList();
    });
  }

  return eAxios({ url: endpoints.reportList, method: "get" })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return handleEFXError(error, count++, getReportList);
    });
};

export const getReportSummary = (
  reportId: string | number,
  count: number = 1
): any => {
  if (!(eAxios.defaults.headers.Authorization && eAxios.defaults.baseURL)) {
    return getDeliveryToken().then(() => {
      return getReportSummary(reportId);
    });
  }

  return eAxios({ url: endpoints.reportSummary(reportId), method: "get" })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return handleEFXError(error, count++, getReportSummary, reportId);
    });
};

export const getMonitoringAlerts = (count: number = 1): any => {
  if (!(eAxios.defaults.headers.Authorization && eAxios.defaults.baseURL)) {
    return getDeliveryToken().then(() => {
      return getMonitoringAlerts();
    });
  }

  return eAxios({ url: endpoints.creditMonitoring, method: "get" })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return handleEFXError(error, count++, getMonitoringAlerts);
    });
};

const handleEFXError = (
  error: any,
  count: number,
  fn: Function,
  data?: any
) => {
  if (!error.response || error.response.status === 401) {
    delete eAxios.defaults.headers.common["Authorization"];
    eAxios.defaults.baseURL = undefined;
    if (count++ > RETRY_COUNT) {
      // return logout();
    }
    return getDeliveryToken().then(() => {
      return data ? fn(count, data) : fn(count);
    });
  }
};

//initialize() {
// //     userLogin(username,password) {
// //         return instance({url: STITCH_BASE_URL+"/users/login", method: "post", data: {username: username, password: password}})
// //         .then((response) => { store.commit("ACCESS_TOKEN", response.data.token); return response.data; })
// //     },
// //     registerUser(data) {
// //         return instance({url: STITCH_BASE_URL+"/users/register", method: "post", data: data})
// //         .then((response) => { store.commit("ACCESS_TOKEN", response.data.token); return response.data; })
// //     },
// }

import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import Button from "@material-ui/core/Button";
import { createStyles, makeStyles } from "@material-ui/core";
import ContentWrapper from "@components/ContentWrapper";
import fontSizes from "@assets/styles/fontSizes";
import Lock from "@assets/images/lock.svg";

const useStyles = makeStyles(theme =>
  createStyles({
    wrapper: {
      padding: "60px 80px",
      [theme.breakpoints.down("md")]: {
        padding: "15px 25px 15px 15px"
      }
    },
    button: {
      width: "100%",
      marginTop: 50
    },
    description: {
      marginTop: "15px",
      lineHeight: "175%",
      letterSpacing: "0.03em",
      fontSize: fontSizes.paragraph1
    }
  })
);
interface IProps {}

const Wrapper = styled.div`
  position: relative;
  text-align: center;
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-bottom: 15px;
`;

const RegisterLock: React.FC<IProps> = () => {
  const classes = useStyles();
  const { pathname } = useLocation();

  const render24HContent = () => {
    return (
      <React.Fragment>
        <p>
          Too many registration attempts from this location <br />
          have resulted in a temporary lockout.
        </p>
        <p>
          Please wait at least 24 hours <br />
          before trying again.
        </p>
      </React.Fragment>
    );
  };

  const render72HContent = () => {
    return (
      <React.Fragment>
        <p>
          Too many recent registration attempts <br />
          have resulted in a temporary lockout.
        </p>
        <p>
          Please wait at least 72 hours <br />
          before trying again.
        </p>
      </React.Fragment>
    );
  };

  return (
    <ContentWrapper>
      <Wrapper className={classes.wrapper}>
        <Logo src={Lock} />
        <h2>Registration Lockout</h2>
        {pathname.indexOf("reg-lock") > -1 && render24HContent()}
        {pathname.indexOf("reg-ip-lock") > -1 && render72HContent()}
        <Button
          to="/login"
          component={Link}
          color="secondary"
          variant="contained"
          className={classes.button}
        >
          Ok
        </Button>
      </Wrapper>
    </ContentWrapper>
  );
};

export default RegisterLock;

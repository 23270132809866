export default {
  hero: "4.125rem",
  hero1: "8.75rem",
  h1: "2.1rem",
  h2: "1.75rem",
  h3: "1.125rem",
  h4: "1rem",
  h5: "0.875rem",
  h6: "0.75rem",
  h7: "0.625rem",
  h8: "0.5rem",
  body: "0.875rem",
  paragraph1: "1rem",
  paragraph2: "0.75rem",
  paragraph3: "0.875rem",
  button: "0.75rem",
  link: "0.875rem",
  caption: "0.75rem",
  subtitle: "0.75rem",
  tooltip: "0.625rem",
  dropdownTitle: "0.625rem",
  loading: "1.125rem",
  calendarTitle: "1rem",
  title: "2.3125rem"
};

import React from "react";
import styled from "styled-components";
import { Link as L } from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Button, InputLabel, FormControl, Select } from "@material-ui/core";
import colors from "@assets/styles/colors";
import fontSizes from "@assets/styles/fontSizes";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      maxWidth: 300,
      "& > *": {
        margin: theme.spacing(1),
        width: "100%"
      }
    },
    submit: {
      marginTop: 100
    },
    description: {
      lineHeight: "175%",
      letterSpacing: "0.03em",
      fontSize: fontSizes.paragraph1
    }
  })
);

interface IProps {
  question: string;
  handleSubmit: any;
  setQuestion: Function;
}

const CancelButton = styled(L)`
  display: block;
  text-align: center;
  text-decoration: none;
  color: ${colors.lightPink};
  font-size: ${fontSizes.paragraph1};
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const NewRecoveryQuestion: React.FC<IProps> = ({
  question,
  setQuestion,
  handleSubmit
}) => {
  const classes = useStyles();

  const handleChange = (e: React.ChangeEvent<any>) => {
    setQuestion(e.target.value);
  };

  return (
    <React.Fragment>
      <Header>
        <h1>
          Change Recovery <br />
          Question
        </h1>
      </Header>
      <form className={classes.root} onSubmit={handleSubmit} color="primary">
        <p className={classes.description}>
          Please select your new recovery question.
        </p>
        <FormControl>
          <InputLabel htmlFor="question">Question</InputLabel>
          <Select
            native
            value={question}
            color="secondary"
            onChange={handleChange}
            inputProps={{
              id: "question",
              name: "question"
            }}
          >
            <option aria-label="None" value="" />
            <option value={0}>Your favorite book?</option>
          </Select>
        </FormControl>
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          className={classes.submit}
        >
          Change
        </Button>
        <CancelButton to={"/settings"}>Cancel</CancelButton>
      </form>
    </React.Fragment>
  );
};

export default NewRecoveryQuestion;

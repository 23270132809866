import React from "react";
import styled from "styled-components";
import { createStyles, makeStyles } from "@material-ui/core";
import ContentWrapper from "@components/ContentWrapper";
import fontSizes from "@assets/styles/fontSizes";
import Unavailable from "@assets/images/unavailable.svg";

const useStyles = makeStyles(theme =>
  createStyles({
    wrapper: {
      padding: "60px 80px",
      [theme.breakpoints.down("md")]: {
        padding: "15px 25px 15px 15px"
      }
    },
    description: {
      marginTop: "15px",
      lineHeight: "175%",
      letterSpacing: "0.03em",
      fontSize: fontSizes.paragraph1
    }
  })
);

interface IProps {}

const Wrapper = styled.div`
  position: relative;
  text-align: center;
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-bottom: 15px;
`;

const RegisterFail: React.FC<IProps> = () => {
  const classes = useStyles();

  return (
    <ContentWrapper>
      <Wrapper className={classes.wrapper}>
        <Logo src={Unavailable} />
        <h2>Registration Unavailable</h2>
        <p className={classes.description}>
          Unfortunately, we cannot continue registration <br />
          at this time.
        </p>
        <p className={classes.description}>
          Please check with your site admin <br />
          or try again later.
        </p>
      </Wrapper>
    </ContentWrapper>
  );
};

export default RegisterFail;
